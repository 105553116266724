import React, { useContext, useEffect, useState } from 'react';

import { WebhookUrlsApi } from '@mtt/admin-api';
import { LinearProgress, Stack } from '@mui/material';
import AppContext from 'AppContext';
import ApariWebhookHostsTable from 'components/ApariWebhookHostsTable';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import { useNavigate } from 'react-router-dom';
import setupAxios from 'services/axios';

const WebhookHosts = () => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const { height, setHeight } = useContext(AppContext);

  const webhookUrlsApi = new WebhookUrlsApi();
  const navigate = useNavigate();

  useEffect(() => {
    setupAxios();
  });

  const getWebhookHosts = async () => {
    webhookUrlsApi
      .webhookUrlControllerGetRedirectUrls()
      .then((res: any) => {
        setRows(res.data);
        setLoading(false);
      })
      .catch(() => {
        setRedirect(true);
      });
  };

  useEffect(() => {
    (async () => {
      setHeight(height === 0 ? 'auto' : null), getWebhookHosts();
    })();

    if (redirect) {
      navigate('/login');
    }
  }, [redirect]);

  return (
    <>
      <h1 className="apari__heading mb-4">Webhook Hosts</h1>

      {loading ? (
        <>
          <Stack sx={{ width: '100%', color: '#673FFA' }} spacing={4}>
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
          </Stack>
        </>
      ) : (
        <>
          <ApariWebhookHostsTable rows={rows} open={open} setOpen={setOpen} getWebhookHosts={getWebhookHosts} />

          <div className="w-48 mt-4">
            <ApariPrimaryButton
              id="webhook-hosts__add-btn"
              text="Add Webhook Host"
              type="button"
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default WebhookHosts;
