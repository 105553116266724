import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import MatUiStyledTable from 'components/UI/MatUiStyledTable';
import UsersTableRow from 'components/UI/UsersTableRow';

const ApariUsersTable = ({ rows, getUsers, isOwner }: { rows: any; getUsers: any; isOwner: boolean }) => {
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  return (
    <>
      <MatUiStyledTable>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row: any) => <UsersTableRow key={row.id} row={row} getUsers={getUsers} isOwner={isOwner} />)}
          </TableBody>
        </Table>
      </MatUiStyledTable>
    </>
  );
};

export default ApariUsersTable;
