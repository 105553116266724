import React, { useState } from 'react';

import './MtlsCertificates.scss';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import { Fade } from '@mui/material';

const MtlsCertificates = () => {
  const [open, setOpen] = useState(false);
  const [csr, setCsr] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formDetails = {
      CSR: csr,
    };

    console.log(formDetails);

    setCsr('');
    setOpen(false);
    setShowAlert(true);
  };

  return (
    <>
      {showAlert ? (
        <Alert variant="filled" severity="success" className="absolute bottom-0 right-0 m-4">
          <span className="pr-5">CSR has been sent!</span>
          <CloseIcon className="hover:cursor-pointer" onClick={() => setShowAlert((prev) => !prev)} />
        </Alert>
      ) : null}

      <h1 className="apari__heading">mTLS Certificates</h1>
      <p className="apari__regular mtls-description mt-3">
        We require a CA signed client certificate in mTLS connection to the APARI APIs. We verify both the CA
        certificate chain and the DN of the client certificate in an allow list.
      </p>
      <h1 className="apari__subheading mt-8">mTLS Certificates</h1>
      <textarea
        name="mtls"
        className="mtls-textarea mt-3 w-full md:w-auto"
        cols={64}
        rows={15}
        defaultValue="-----BEGIN CERTIFICATE-----
        MIICUTCCAfugAwIBAgIBADANBgkqhkiG9w0BAQQFADBXMQswCQYDVQQGEwJDTjEL
        MAkGA1UECBMCUE4xCzAJBgNVBAcTAkNOMQswCQYDVQQKEwJPTjELMAkGA1UECxMC
        VU4xFDASBgNVBAMTC0hlcm9uZyBZYW5nMB4XDTA1MDcxNTIxMTk0N1oXDTA1MDgx
        NDIxMTk0N1owVzELMAkGA1UEBhMCQ04xCzAJBgNVBAgTAlBOMQswCQYDVQQHEwJD
        TjELMAkGA1UEChMCT04xCzAJBgNVBAsTAlVOMRQwEgYDVQQDEwtIZXJvbmcgWWFu
        ZzBcMA0GCSqGSIb3DQEBAQUAA0sAMEgCQQCp5hnG7ogBhtlynpOS21cBewKE/B7j
        V14qeyslnr26xZUsSVko36ZnhiaO/zbMOoRcKK9vEcgMtcLFuQTWDl3RAgMBAAGj
        gbEwga4wHQYDVR0OBBYEFFXI70krXeQDxZgbaCQoR4jUDncEMH8GA1UdIwR4MHaA
        FFXI70krXeQDxZgbaCQoR4jUDncEoVukWTBXMQswCQYDVQQGEwJDTjELMAkGA1UE
        CBMCUE4xCzAJBgNVBAcTAkNOMQswCQYDVQQKEwJPTjELMAkGA1UECxMCVU4xFDAS
        BgNVBAMTC0hlcm9uZyBZYW5nggEAMAwGA1UdEwQFMAMBAf8wDQYJKoZIhvcNAQEE
        BQADQQA/ugzBrjjK9jcWnDVfGHlk3icNRq0oV7Ri32z/+HQX67aRfgZu7KWdI+Ju
        Wm7DCfrPNGVwFWUQOmsPue9rZBgO
        -----END CERTIFICATE-----"
      ></textarea>

      <div className="w-36 mt-4">
        <ApariPrimaryButton
          text="Upload CSR"
          type="button"
          onClick={() => {
            setOpen(true);
          }}
        />
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <form action="" onSubmit={handleSubmit}>
                <div className="flex justify-between items-center">
                  <h2 className="apari__subheading">Upload CSR</h2>
                  <CloseIcon className="hover:cursor-pointer" onClick={() => setOpen(false)} />
                </div>
                <textarea
                  name="mtls"
                  className="mtls-textarea my-3 w-full"
                  rows={15}
                  value={csr}
                  onChange={(e: any) => setCsr(e.target.value)}
                ></textarea>
                <ApariPrimaryButton text="Send" type="submit" />
              </form>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default MtlsCertificates;
