import React, { useContext, useEffect, useState } from 'react';

import { ClientApi } from '@mtt/admin-api';
import { LinearProgress, Stack } from '@mui/material';
import AppContext from 'AppContext';
import ApariClientSetupTable from 'components/ApariClientSetupTable';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import { useNavigate } from 'react-router-dom';
import setupAxios from 'services/axios';

const ClientSetup = () => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const { setHeight } = useContext(AppContext);

  const clientApi = new ClientApi();
  const navigate = useNavigate();

  useEffect(() => {
    setupAxios();
    setHeight(0);

    (async () => {
      getClients();
    })();

    if (redirect) {
      navigate('/login');
    }
  }, [redirect]);

  const getClients = async () => {
    clientApi
      .clientControllerGetClients()
      .then((res) => {
        setRows(res.data);
        setLoading(false);
      })
      .catch(() => {
        setRedirect(true);
      });
  };

  return (
    <>
      <h1 className="apari__heading mb-4">Client setup</h1>

      {loading ? (
        <>
          <Stack sx={{ width: '100%', color: '#673FFA' }} spacing={4}>
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
          </Stack>
        </>
      ) : (
        <>
          <ApariClientSetupTable rows={rows} open={open} setOpen={setOpen} getClients={getClients} />

          {/* {rows.clients.length === 0 ? ( */}
          <div className="w-48 mt-4">
            <ApariPrimaryButton
              id="client-setup__create-btn"
              text="Create New Client"
              type="button"
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
          {/* ) : null} */}
        </>
      )}
    </>
  );
};

export default ClientSetup;
