import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Profile.scss';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { Class2faApi, UsersApi } from '@mtt/admin-api';
import { Fade, TextField } from '@mui/material';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import ApariSecondaryButton from 'components/UI/ApariSecondaryButton';
import FormField from 'components/UI/FormField';
import Toggle from 'components/UI/Toggle';
import setupAxios from 'services/axios';
import AppContext from 'AppContext';
import Cookies from 'js-cookie';

const Profile = ({ user, setCheckJwt }: { user: any; setCheckJwt: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [owner, setOwner] = useState(null);
  const [showPasswordBtn, setShowPasswordBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const [twoFactorAuthenticationCode, setTwoFactorAuthenticationCode] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage, setHeight } = useContext(AppContext);

  const usersApi: any = new UsersApi();
  const class2faApi: any = new Class2faApi();
  const navigate = useNavigate();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
    setHeight(0);

    const loggedInUser = localStorage.getItem('user');
    let userFromLocalStorage;
    if (loggedInUser) {
      userFromLocalStorage = JSON.parse(loggedInUser);
    } else {
      navigate('/login');
      return;
    }

    if (!userFromLocalStorage.isSecondFactorAuthenticated) {
      navigate('/login');
      setShowErrorAlert(true);
      setMessage('You must be 2FA to access your profile');
    }

    (async () => {
      getUserDetails();
    })();

    if (currentPassword.length > 0) {
      setShowIcon(true);
      setShowPasswordBtn(true);
    } else {
      setShowIcon(false);
      setShowPasswordBtn(false);
    }

    setTimeout(() => {
      if (redirect) {
        navigate('/two-factor-setup');
      }
    }, 0);
  }, [currentPassword, redirect]);

  const getUserDetails = async () => {
    if (Cookies.get('JWT') === undefined) {
      localStorage.clear();
    }

    const loggedInUser = localStorage.getItem('user');
    let userFromLocalStorage;
    if (loggedInUser) {
      userFromLocalStorage = JSON.parse(loggedInUser);
    } else {
      navigate('/login');
    }

    usersApi
      .adminUiUsersControllerGetUser(userFromLocalStorage.userId)
      .then((res: any) => {
        setEmail(res.data.mail);
        setDisplayName(res.data.displayName);
        setOwner(res.data.owner);
      })
      .catch(() => {
        navigate('/login');
      });
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);

    const x = document.getElementById('profile__current-password') as HTMLInputElement;
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };

  const handleUpdateName = (e: any) => {
    e.preventDefault();

    usersApi
      .adminUiUsersControllerUpdate(user.userId, { displayName })
      .then(() => {
        setShowSuccessAlert(true);
        setMessage('Your display name has been updated.');
      })
      .catch((err: any) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  const handlePasswordSubmit = async (e: any) => {
    e.preventDefault();

    usersApi
      .adminUiUsersControllerUpdatePassword(user.userId, { currentPassword, newPassword, confirmNewPassword })
      .then(() => {
        setShowSuccessAlert(true);
        setMessage('Your password has been updated.');
        getUserDetails();
      })
      .catch((err: any) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  const handleRemove2fa = (e: any) => {
    e.preventDefault();

    class2faApi
      .twoFactorAuthenticationControllerRemoveSecondFactor({ twoFactorAuthenticationCode })
      .then((res: any) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setShowErrorAlert(true);
          return;
        }

        setShowSuccessAlert(true);
        setRedirect(true);
        setIsError(false);
        setHelperText(null);
        setMessage('Two-factor has been turned off.');
        setCheckJwt(true);
      })
      .catch((err: any) => {
        setHelperText(err.response.data.message);
        setIsError(true);
      });
  };

  return (
    <>
      <h1 className="apari__heading mb-4">Edit your profile</h1>
      <div className="w-full lg:w-96">
        <form onSubmit={handleUpdateName}>
          <div className="flex flex-col">
            <TextField
              sx={{ marginBottom: '1rem' }}
              id="profile__email"
              type="email"
              label="Email"
              value={email}
              variant="standard"
              disabled
            />
            <TextField
              sx={{ marginBottom: '1rem' }}
              id="profile__name"
              type="text"
              label="Display name"
              value={displayName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDisplayName(e.target.value)}
              variant="standard"
              error={isError}
              helperText={helperText}
              required
            />
          </div>
          <div className="mb-6">
            <ApariSecondaryButton id="profile__update-name-btn" text="Update Name" type="submit" />
          </div>
        </form>
        <form onSubmit={handlePasswordSubmit}>
          <div className="flex flex-col">
            <TextField
              sx={{ marginBottom: '1rem' }}
              id="profile__current-password"
              type="password"
              label="Current password"
              value={currentPassword}
              onChange={(e: any) => setCurrentPassword(e.target.value)}
              variant="standard"
              error={isError}
              helperText={helperText}
            />
            <TextField
              sx={{ marginBottom: '1rem' }}
              id="profile__new-password"
              type="password"
              label="New password"
              onChange={(e: any) => setNewPassword(e.target.value)}
              variant="standard"
              error={isError}
              helperText={helperText}
            />
            <TextField
              sx={{ marginBottom: '1rem' }}
              id="profile__confirm-new-password"
              type="password"
              label="Confirm new password"
              onChange={(e: any) => setConfirmNewPassword(e.target.value)}
              variant="standard"
              error={isError}
              helperText={helperText}
            />
          </div>
          {showPasswordBtn ? (
            <ApariSecondaryButton id="profile__update-password-btn" text="Update Password" type="submit" />
          ) : null}
        </form>
      </div>
      <h2 className="apari__regular profile-subheading mt-7">Turn off two-factor authentication</h2>
      <div className="w-36 mt-1">
        <ApariPrimaryButton
          id="profile__turn-off-two-factor-btn"
          text="Turn Off"
          type="button"
          onClick={() => setOpen(true)}
        />
      </div>
      <h2 className="apari__regular profile-subheading mt-6">Owner</h2>

      <Toggle
        text={owner ? 'Yes' : 'No'}
        toggleStyle={owner ? { backgroundColor: '#673FFA' } : { backgroundColor: '#cdcccc' }}
        innerRectangleStyle={owner ? { right: '2px' } : { left: '2px' }}
        textStyle={owner ? { left: 0 } : { right: 0 }}
        display={true}
      />

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Enter Two-factor Code</h2>
              <CloseIcon id="profile__close-btn" className="hover:cursor-pointer" onClick={() => setOpen(false)} />
            </div>

            <form onSubmit={handleRemove2fa}>
              <TextField
                id="profile__two-factor-code"
                fullWidth
                margin="normal"
                type="text"
                label="Two-factor Code"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTwoFactorAuthenticationCode(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
              />
              <div className="mt-6">
                <ApariPrimaryButton id="profile__submit-two-factor-turn-off-btn" text="Turn Off" type="submit" />
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Profile;
