import React from 'react';
import { Outlet } from 'react-router-dom';

const ProtectedRoute = ({ isAllowed, children }: { isAllowed: any; children?: any }) => {
  if (!isAllowed) {
    // return <Login />;
    // return <Navigate to={redirectPath} />;
    // navigate(redirectPath);
  }
  return children ? children : <Outlet />;

  // return isAllowed ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
