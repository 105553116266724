import { Buffer } from 'buffer';
import React, { useContext, useEffect, useState } from 'react';

import './TwoFactorSetup.scss';

import { Class2faApi } from '@mtt/admin-api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import AppContext from 'AppContext';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import CopyToClipboard from 'react-copy-to-clipboard';
import { NavLink, useNavigate } from 'react-router-dom';
import setupAxios from 'services/axios';

const TwoFactorSetup = () => {
  const [qrcode, setQrcode] = useState('');
  const [twoFactorSecret, setTwoFactorSecret] = useState('');
  const [showTwoFactor, setShowTwoFactor] = useState(false);

  const { setShowSuccessAlert, setMessage } = useContext(AppContext);

  const twoFactorApi = new Class2faApi();
  const navigate = useNavigate();

  useEffect(() => {
    setupAxios();

    const loggedInUser = localStorage.getItem('user');
    let userFromLocalStorage;
    if (loggedInUser) {
      userFromLocalStorage = JSON.parse(loggedInUser);
    }

    if (loggedInUser && userFromLocalStorage.secondFactorReady) {
      navigate('/safe-recovery-key');
    }

    if (loggedInUser && userFromLocalStorage.isSecondFactorAuthenticated) {
      navigate('/dashboard');
    }

    twoFactorApi
      .twoFactorAuthenticationControllerRegister({ responseType: 'arraybuffer' })
      .then((res: any) => {
        const base64ImageString = Buffer.from(res.data, 'binary').toString('base64');
        setQrcode('data:image/png;base64,' + base64ImageString);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          navigate('/safe-recovery-key');
        }
      });
  }, []);

  const fetchSecret = async () => {
    twoFactorApi.twoFactorAuthenticationControllerGetUserSecret().then((res: any) => {
      setTwoFactorSecret(res.data.secret);
      setShowTwoFactor(true);

      setTimeout(() => {
        setShowTwoFactor(false);
      }, 4000);
    });
  };

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <div>
          <h1 className="apari__heading mb-4">Authentication</h1>
          <h2 className="apari__subheading">Link Google Authenticator</h2>
          <p className="two-factor__paragraph mt-4">
            Authenticate via Google by scanning the QR code below or manually entering the code into your app.
            <a
              href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en"
              target="_blank"
              rel="noreferrer"
              className="underline font-bold pl-1"
            >
              Learn more about Google Authenticator
            </a>
          </p>
          <div className="two-factor__qr-cod">
            <img src={qrcode} alt="qrcode" />
          </div>
          <p className="two-factor__paragraph">
            QR Code not scanning? Enter the code manually into your app by clicking the button below to reveal your
            secret key which you will need to input into the app.
          </p>
          {showTwoFactor ? (
            <div className="flex items-center mt-4">
              <CopyToClipboard
                text={twoFactorSecret}
                onCopy={() => {
                  setShowSuccessAlert(true);
                  setMessage('Copied to clipboard');
                }}
              >
                <Tooltip title="Copy">
                  <IconButton
                    id="two-factor__copy-icon-btn"
                    className="hover:cursor-pointer"
                    aria-label="delete"
                    size="small"
                  >
                    <ContentCopyIcon className="p-1" />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
              <p className="apari__regular pl-2 text-xs font-semibold">{twoFactorSecret}</p>
            </div>
          ) : (
            <button
              id="two-factor__show-secret-btn"
              onClick={fetchSecret}
              className="apari__secret-text text-xs font-semibold uppercase transition-colors duration-150 mt-4"
            >
              Show Secret
            </button>
          )}

          <div className="w-72 mt-4">
            <NavLink to="/safe-recovery-key">
              <ApariPrimaryButton id="two-factor__confirm-btn" type="button" text="Confirm" />
            </NavLink>
            <div className="text-center my-2">
              <NavLink to="/profile" className="two-factor__link-text">
                Cancel
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoFactorSetup;
