import globalAxios from 'axios';
import { GLOBAL } from 'constants/index';

const setupAxios = (): void => {
  globalAxios.defaults.withCredentials = true;
  globalAxios.interceptors.request.use(
    (config: any) => {
      if (!config.url.includes(GLOBAL.ADMIN_API_BASE_URL)) {
        config.url = config.url.replace('http://localhost', GLOBAL.ADMIN_API_BASE_URL);
      }
      return config;
    },
    (error: any) => {
      Promise.reject(error);
    },
  );
};

export default setupAxios;
