import React, { SyntheticEvent, useContext } from 'react';

import { Alert, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppContext from 'AppContext';

const SnackbarAlert = () => {
  const { showSuccessAlert, setShowSuccessAlert, showErrorAlert, setShowErrorAlert, message } = useContext(AppContext);

  const handleCloseSuccessAlert = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSuccessAlert(false);
  };

  const successAction = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSuccessAlert}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleCloseErrorAlert = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowErrorAlert(false);
  };

  const errorAction = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSuccessAlert}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSuccessAlert}
        autoHideDuration={5000}
        onClose={handleCloseSuccessAlert}
        action={successAction}
      >
        <Alert variant="filled" onClose={handleCloseSuccessAlert} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showErrorAlert}
        autoHideDuration={5000}
        onClose={handleCloseErrorAlert}
        action={errorAction}
      >
        <Alert variant="filled" onClose={handleCloseErrorAlert} severity="error" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarAlert;
