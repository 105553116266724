import React, { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { RedirectUrlsApi } from '@mtt/admin-api';
import { Fade, TextField } from '@mui/material';
import AppContext from 'AppContext';
import ApariDropdown from 'components/UI/ApariDropdown';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import setupAxios from 'services/axios';

const RedirectUrlsTableRow = ({ row, getRedirectUrls }: { row: any; getRedirectUrls: any }) => {
  const [active, setActive] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [urlInfo, setUrlInfo] = useState('');
  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const redirectUrlsApi: any = new RedirectUrlsApi();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
  });

  const handleEditSubmit = async (e: any) => {
    e.preventDefault();

    redirectUrlsApi
      .redirectControllerUpdate(row.id, {
        url: urlInfo,
      })
      .then((res: any) => {
        setUrlInfo(res.data.url);
        setEditModal(false);
        setShowSuccessAlert(true);
        setMessage('Redirect URL has been updated.');
        getRedirectUrls();
      })
      .catch((err: any) => {
        setHelperText(err.response.data.message);
        setIsError(true);
      });
  };

  const handleEditModalClick = async () => {
    redirectUrlsApi.redirectControllerGetRedirectUrlById(row.id).then((res: any) => {
      setUrlInfo(res.data.url);
      setEditModal(true);
    });
  };

  const handleDeleteRedirect = async () => {
    redirectUrlsApi
      .redirectControllerDelete(row.id)
      .then(() => {
        setShowSuccessAlert(true);
        setMessage('Redirect URL has been deleted.');
        getRedirectUrls();
      })
      .catch((err: any) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} id={row.id}>
        <TableCell>{row.url}</TableCell>
        <TableCell className="relative flex justify-between items-center">
          Active
          <ApariDropdown
            active={active}
            setActive={setActive}
            id={`apari-dropdown__btn--${row.url.toLowerCase().replaceAll('.', '-')}`}
          >
            <div className="apari-dropdown__menu">
              <ul className="apari-dropdown__menu--list">
                <li
                  id={`redirect-urls__edit--${row.url.toLowerCase().replaceAll('.', '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={handleEditModalClick}
                >
                  Edit URL
                </li>
                <li
                  id={`redirect-urls__remove--${row.url.toLowerCase().replaceAll('.', '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={handleDeleteRedirect}
                >
                  Remove URL
                </li>
              </ul>
            </div>
          </ApariDropdown>
        </TableCell>
      </TableRow>

      <Modal
        open={editModal}
        onClose={() => {
          setEditModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={editModal}>
          <Box sx={style}>
            <h2 className="apari__subheading mb-3">Edit Redirect URL</h2>

            <form onSubmit={handleEditSubmit}>
              <TextField
                id="redirect-urls__edit--input"
                fullWidth
                margin="normal"
                type="text"
                label="Edit URL"
                value={urlInfo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUrlInfo(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
              />

              <div className="mt-6">
                <ApariPrimaryButton id="redirect-urls__save-btn" text="Save" type="submit" />
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RedirectUrlsTableRow;
