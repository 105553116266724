import React, { SetStateAction, useContext, useEffect, useState } from 'react';

import './ClientSetupTableRow.scss';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ApariDropdown from 'components/UI/ApariDropdown';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import FormField from 'components/UI/FormField';
import { CircularProgress, Fade, IconButton, TextField, Tooltip } from '@mui/material';
import setupAxios from 'services/axios';
import { ClientApi } from '@mtt/admin-api';
import AppContext from 'AppContext';
import CopyToClipboard from 'react-copy-to-clipboard';

const ClientSetupTableRow = ({
  row,
  setOpen,
  getClients,
  scopes,
  setScopes,
}: {
  row: any;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  getClients: any;
  scopes: Array<string>;
  setScopes: React.Dispatch<SetStateAction<Array<string>>>;
}) => {
  const [active, setActive] = useState(false);
  const [scope, setScope] = useState('');
  const [openViewDetails, setOpenViewDetails] = useState<boolean>(false);
  const [addRowModal, setAddRowModal] = useState<boolean>(false);
  const [secret, setSecret] = useState(row.secretRepresentation);
  const [hashedSecret, setHashedSecret] = useState(row.secretRepresentation);
  const [loading, setLoading] = useState<boolean>(false);
  const [showCopyIcon, setShowCopyIcon] = useState<boolean>(false);
  const [showRefreshIcon, setShowRefreshIcon] = useState<boolean>(true);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const clientApi = new ClientApi();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '80%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
  });

  const handleViewDetails = () => {
    setOpenViewDetails(true);
    setActive(false);
  };

  const handleDeleteClient = async () => {
    clientApi
      .clientControllerDelete(row.id)
      .then(() => {
        setShowSuccessAlert(true);
        setMessage('Client has been deleted.');
        getClients();
      })
      .catch((err) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  const handleCloseHeadersModal = () => {
    setOpen(true);
    setAddRowModal(false);
  };

  const handleScopesSubmit = (e: any) => {
    e.preventDefault();

    setScopes([...scopes, scope]);
    setOpen(true);
    setAddRowModal(false);
  };

  const refreshSecret = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setShowRefreshIcon(false);

    await fetch(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/protected/client/generate-new-secret/${row.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.error) {
          setMessage(data.message);
          setShowErrorAlert(true);
          return;
        }

        setShowCopyIcon(true);
        setSecret(data.secret);
        setHashedSecret(data.hashedSecret);
      })
      .catch((err) => {
        setLoading(false);
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  const handleCloseViewDetailsModal = () => {
    setShowCopyIcon(false);
    setShowRefreshIcon(true);
    setOpenViewDetails(false);
    setSecret(hashedSecret);
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{row.name}</TableCell>
        <TableCell className="relative flex justify-between items-center">
          {row.clientId}

          <ApariDropdown
            active={active}
            setActive={setActive}
            id={`apari-dropdown__btn--${row.name.toLowerCase().replaceAll(' ', '-')}`}
          >
            <div className="apari-dropdown__menu">
              <ul className="apari-dropdown__menu--list">
                <li
                  id={`client-setup__view-details--${row.name.toLowerCase().replaceAll(' ', '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={handleViewDetails}
                >
                  View Details
                </li>
                <li
                  id={`client-setup__remove-client--${row.name.toLowerCase().replaceAll(' ', '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={handleDeleteClient}
                >
                  Remove Client
                </li>
              </ul>
            </div>
          </ApariDropdown>
        </TableCell>
      </TableRow>

      <Modal
        open={addRowModal}
        onClose={() => {
          setAddRowModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={addRowModal}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Add Scope</h2>
              <CloseIcon
                id="client-setup__close-headers-modal"
                className="hover:cursor-pointer"
                onClick={handleCloseHeadersModal}
              />
            </div>

            <form onSubmit={handleScopesSubmit}>
              <FormField
                id="client-setup__scope-name"
                type="text"
                label="Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setScope(e.target.value)}
              />

              <div className="mt-6">
                <ApariPrimaryButton id="client-setup__scope-save-btn" text="Save" type="submit" />
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={openViewDetails}
        onClose={() => {
          setOpenViewDetails(false);
        }}
        closeAfterTransition
      >
        <Fade in={openViewDetails}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Client details</h2>
              <CloseIcon className="hover:cursor-pointer" onClick={handleCloseViewDetailsModal} />
            </div>
            <div className="py-3">
              <TextField
                id="client-setup__details-id"
                type="text"
                label="Client id"
                variant="outlined"
                value={row.clientId}
                size="small"
                color="primary"
                disabled
                fullWidth
              />
            </div>
            <div className="py-3">
              <TextField
                id="client-setup__details-name"
                type="text"
                label="Client name"
                variant="outlined"
                value={row.name}
                size="small"
                disabled
                fullWidth
              />
            </div>
            <div className="py-3 flex">
              <TextField
                id="client-setup__details-scope"
                className="!mr-2"
                type="text"
                label="Scope"
                variant="outlined"
                value={row.scope.toString().replace(/,/g, ' ')}
                size="small"
                disabled
                fullWidth
              />
              <CopyToClipboard
                text={row.scope.toString().replace(/,/g, ' ')}
                onCopy={() => {
                  setShowSuccessAlert(true);
                  setMessage('Copied to clipboard');
                }}
              >
                <Tooltip title="Copy Scope">
                  <IconButton
                    id="webhook-configuration__results-refresh-btn"
                    aria-label="Copy Scope Button"
                    color="default"
                  >
                    <ContentCopyIcon sx={{ padding: '2px' }} />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="py-3 flex items-center">
              <TextField
                id="client-setup__details-secret outlined-basic"
                className="!mr-2"
                type="text"
                label="Client secret"
                variant="outlined"
                value={secret}
                size="small"
                disabled
                fullWidth
              />
              {loading && <CircularProgress sx={{ marginX: '9px' }} color="primary" size={25} />}
              {showRefreshIcon && (
                <Tooltip title="Generate New Secret">
                  <IconButton
                    id="webhook-configuration__results-refresh-btn"
                    aria-label="Refresh Webhook Results"
                    color="default"
                    onClick={refreshSecret}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              )}
              {showCopyIcon && (
                <CopyToClipboard
                  text={secret}
                  onCopy={() => {
                    setShowSuccessAlert(true);
                    setMessage('Copied to clipboard');
                  }}
                >
                  <Tooltip title="Copy Secret">
                    <IconButton id="client-setup__copy-secret" aria-label="Copy Secret Button">
                      <ContentCopyIcon sx={{ padding: '2px' }} />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ClientSetupTableRow;
