import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './TopNav.scss';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Cookies from 'js-cookie';
import setupAxios from 'services/axios';
import { AuthApi, UsersApi } from '@mtt/admin-api';

const TopNav = ({ user, setUser }: { user: any; setUser: any }) => {
  const [active, setActive] = useState(false);
  const [userDetails, setUserDetails] = useState(user);

  const authApi = new AuthApi();
  const usersApi = new UsersApi();
  const navigate = useNavigate();

  useEffect(() => {
    setupAxios();

    usersApi.adminUiUsersControllerGetUser(user && user.userId).then((res: any) => {
      setUserDetails(res.data);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickEvent);

    return () => {
      window.removeEventListener('mousedown', handleClickEvent);
    };
  }, [active]);

  const handleClickEvent = (e: any) => {
    const currentDropdown = e.target.closest('[data-dropdown]');

    document.querySelectorAll('[data-dropdown].active').forEach((dropdown) => {
      if (dropdown === currentDropdown) return;
      dropdown.classList.remove('active');
      setActive((prev: any) => !prev);
    });
  };

  const handleLogout = async () => {
    authApi.authControllerLogout().then(() => {
      const cookies = Cookies.get('JWT');

      if (cookies === undefined) {
        navigate('/login');
        setUser(null);
        localStorage.clear();
      }
    });
  };

  return (
    <>
      <nav className="top-navigation shadow-md">
        <div
          className={active ? 'top-navigation__user--dropdown active' : 'top-navigation__user--dropdown'}
          data-dropdown
        >
          <button
            id="top-nav__dropdown-btn"
            className="dropdown-wrapper"
            onClick={() => setActive((prev: boolean) => !prev)}
          >
            <div className="user-icon" data-dropdown-button>
              <span className="user-icon__initial disable-select">
                {userDetails.displayName && userDetails.displayName.charAt(0)}
              </span>
            </div>
            <div className="dropdown-arrow">
              <KeyboardArrowDownIcon data-dropdown-button />
            </div>
          </button>

          <div className="dropdown-menu">
            <div className="dropdown-menu__user">
              <div className="user-icon">
                <span className="user-icon__initial disable-select">
                  {userDetails.displayName && userDetails.displayName.charAt(0)}
                </span>
                {/* <span className="user-icon__initial">J</span> */}
              </div>
              <div className="dropdown-menu__user--details">
                <h1 className="dropdown-menu__user--name">{userDetails.displayName}</h1>
                <p className="dropdown-menu__user--email">{userDetails.mail}</p>
              </div>
            </div>
            <ul className="dropdown-menu__list">
              <li className="dropdown-menu__list--item">
                <NavLink id="top-nav__profile-link" to="/profile" onClick={() => setActive(false)}>
                  Profile
                </NavLink>
              </li>
              <li id="top-nav__logout-link" className="dropdown-menu__list--item" onClick={handleLogout}>
                Log out
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default TopNav;
