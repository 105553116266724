import React from 'react';

import './ApariSecondaryButton.scss';

const ApariSecondaryButton = ({
  type,
  text,
  onClick,
  id,
}: {
  type: 'button' | 'submit' | 'reset' | undefined;
  text: string;
  onClick?: any;
  id?: any;
}) => {
  return (
    <>
      <button id={id} className="apari__secondary-btn" type={type} onClick={onClick}>
        {text}
      </button>
    </>
  );
};

export default ApariSecondaryButton;
