import React, { useContext, useEffect, useState } from 'react';

import './WebhooksTableRow.scss';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { WebhookConfigurationsApi } from '@mtt/admin-api';
import {
  Checkbox,
  checkboxClasses,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import AppContext from 'AppContext';
import ApariDropdown from 'components/UI/ApariDropdown';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import MatUiStyledTable from 'components/UI/MatUiStyledTable';
import moment from 'moment';
import AnimateHeight, { Height } from 'react-animate-height';
import setupAxios from 'services/axios';
import ApariSecondaryButton from 'components/UI/ApariSecondaryButton';
import Toggle from 'components/UI/Toggle';

const WebhooksTableRow = ({
  row,
  getWebhookConfigurations,
  reportList,
}: {
  row: any;
  getWebhookConfigurations: any;
  reportList: any;
}) => {
  const [active, setActive] = useState(false);
  const [addRowModal, setAddRowModal] = useState(false);
  const [openWebhookConfigModal, setOpenWebhookConfigModal] = useState(false);
  const [extraHeaders, setExtraHeaders] = useState<{ name: string; value: string }[]>([]);
  const [name, setName] = useState<string | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const [noOfRetries, setNoOfRetries] = useState<string>(row.maxRetries.toString());
  const [delay, setDelay] = useState<string>(row.retryDelay.toString());
  const [enabled, setEnabled] = useState(row.active);
  const [reports, setReports] = useState<Array<string>>(row.reports);
  const [events, setEvents] = useState<Array<string>>(row.events ? row.events : []);
  const [helperText, setHelperText] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingResults, setLoadingResults] = useState<boolean>(false);
  const [resultsHeight, setResultsHeight] = useState<Height>(0);
  const [results, setResults] = useState<any[] | null>(null);
  const [timestamp, setTimestamp] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const webhookConfigurationsApi: any = new WebhookConfigurationsApi();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    maxHeight: '80%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  const confirmStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '80%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
  });

  const handleAddHeadersModal = () => {
    setOpenWebhookConfigModal(false);
    setAddRowModal(true);
  };

  const handleCloseHeadersModal = () => {
    setOpenWebhookConfigModal(true);
    setAddRowModal(false);
  };

  const handleExtraHeadersSubmit = (e: any) => {
    e.preventDefault();

    if (!name) {
      setHelperText('Name cannot be empty');
      setIsError(true);
      return;
    }

    if (!value) {
      setHelperText('Value cannot be empty');
      setIsError(true);
      return;
    }

    if (name && value)
      setExtraHeaders((prevExtraHeaders) =>
        prevExtraHeaders ? [...prevExtraHeaders, { name, value }] : [{ name, value }],
      );
    setOpenWebhookConfigModal(true);
    setAddRowModal(false);
    setName(null);
    setValue(null);
  };

  const handleWebhookConfigSubmit = async (e: any) => {
    e.preventDefault();

    webhookConfigurationsApi
      .webhookConfigurationControllerUpdate(row.id, {
        maxRetries: parseInt(noOfRetries),
        retryDelay: parseInt(delay),
        extraHeaders,
        reports,
        events,
        active: enabled,
      })
      .then(() => {
        setOpenWebhookConfigModal(false);
        setShowSuccessAlert(true);
        setMessage('Webhook has been configured.');
        setExtraHeaders([]);
        setReports([]);
        setEvents([]);
        getWebhookConfigurations();
      })
      .catch((err: any) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  const handleDeleteWebhookConfig = async () => {
    webhookConfigurationsApi
      .webhookConfigurationControllerDelete(row.id)
      .then(() => {
        setShowSuccessAlert(true);
        setMessage('Webhook Configuration has been deleted.');
        getWebhookConfigurations();
        setShowConfirmModal(false);
      })
      .catch((err: any) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  const handleRemoveRow = (header: any) => {
    setExtraHeaders(extraHeaders.filter((elem: any) => elem !== header));
  };

  const handleViewDetails = async (e: any) => {
    e.preventDefault();

    getWebhookResults();

    setExtraHeaders(row.extraHeaders ? row.extraHeaders : extraHeaders);
    setReports(row.reports ? row.reports : reports);
    setEvents(row.events ? row.events : events);
    setOpenWebhookConfigModal(true);
    setActive(false);
  };

  const handleChange = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setReports([...reports, value]);
    } else {
      setReports(reports.filter((elem: any) => elem !== value));
    }
  };

  const handleEventChange = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setEvents([...events, value]);
    } else {
      setEvents(events.filter((elem: any) => elem !== value));
    }
  };

  const handleTestWebhookConfiguration = async () => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/protected/webhook-configurations/test/${row.id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.error) {
          setMessage(data.message);
          setShowErrorAlert(true);
          return;
        }

        const firstDigitStr =
          typeof data.responseStatusCode === 'string' ? data.responseStatusCode[0] : String(data.resoonseStatusCode)[0];
        const firstDigitNum = Number(firstDigitStr);

        if (isNaN(firstDigitNum)) {
          setMessage(data.error.message);
          setShowErrorAlert(true);
          return;
        } else if (firstDigitNum === 2) {
          setMessage(`Webhook returned with status ${data.responseStatusCode}`);
          setShowSuccessAlert(true);
        } else {
          setMessage(`Webhook returned with status ${data.responseStatusCode}`);
          setShowErrorAlert(true);
        }
      })
      .catch((err) => {
        console.log(err);
        // setMessage(err.response.data);
        // setShowErrorAlert(true);
      });
  };

  const getWebhookResults = () => {
    setLoadingResults(true);

    fetch(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/protected/webhook-configurations/results/${row.id}`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        setLoadingResults(false);

        if (data.error) {
          setMessage(data.message);
          setShowErrorAlert(true);
          return;
        }

        setResults(data);

        data.map((result: any) => {
          const date = new Date(result.webhook_config.created_at);
          const formatDate = moment(date).local();
          const local = formatDate.format('MMMM Do YYYY, h:mm:ss a');
          setTimestamp(local);
        });
      });
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{row.url}</TableCell>
        {/* <TableCell> */}
        {/* {row.status === 'red' ? ( */}
        {/* <span className="text-gray-400">
            <CircleIcon fontSize="small" />
          </span> */}
        {/* ) : null} */}
        {/* {row.status === 'yellow' ? (
            <span className="text-yellow-500">
              <CircleIcon fontSize="small" />
            </span>
          ) : null}
          {row.status === 'green' ? (
            <span className="text-green-500">
              <CircleIcon fontSize="small" />
            </span>
          ) : null}
        </TableCell> */}
        <TableCell className="relative flex justify-between items-center">
          {enabled ? 'Enabled' : 'Disabled'}

          <ApariDropdown
            active={active}
            setActive={setActive}
            id={`apari-dropdown__btn--${row.url
              .toLowerCase()
              .replace(':', '')
              .replace('//', '/')
              .replaceAll(/[/.]/g, '-')}`}
          >
            <div className="apari-dropdown__menu">
              <ul className="apari-dropdown__menu--list">
                <li
                  id={`webhook-configuration__edit-link--${row.url
                    .toLowerCase()
                    .replace(':', '')
                    .replace('//', '/')
                    .replaceAll(/[/.]/g, '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={handleViewDetails}
                >
                  Configure Webhook
                </li>
                {/* <li className="apari-dropdown__menu--list-item" onClick={handleUpdateEnabled}>
                  {row.active ? 'Disable' : 'Enable'}
                </li> */}
                <li
                  id={`webhook-configuration__remove-link--${row.url
                    .toLowerCase()
                    .replace(':', '')
                    .replace('//', '/')
                    .replaceAll(/[/.]/g, '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={() => {
                    setShowConfirmModal(true);
                    setActive(false);
                  }}
                >
                  Remove
                </li>
              </ul>
            </div>
          </ApariDropdown>
        </TableCell>
      </TableRow>

      <Modal
        open={openWebhookConfigModal}
        onClose={() => {
          setOpenWebhookConfigModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={openWebhookConfigModal}>
          <Box sx={style}>
            <h2 className="apari__subheading mb-3">Update Webhook Configuration</h2>
            <h3 className="apari__subheading">URL</h3>
            <div className="flex items-center justify-between mb-4 bg-gray-50 rounded-sm px-4 py-1">
              <p className="apari__regular">{row.url}</p>
              <div className="flex items-center justify-center h-10 w-16 ">
                {loading ? (
                  <CircularProgress color="primary" size={30} />
                ) : (
                  <Button
                    id="webhook-configuration__test-btn"
                    style={{
                      color: '#673ffa',
                    }}
                    onClick={handleTestWebhookConfiguration}
                  >
                    Test
                  </Button>
                )}
              </div>
            </div>

            <Button
              id="webhook-configuration__view-results-btn"
              className={resultsHeight === 0 ? 'rounded-sm results-btn' : 'rounded-sm results-btn active'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                padding: '1rem',
                color: '#000',
              }}
              onClick={() => setResultsHeight(resultsHeight === 0 ? 'auto' : 0)}
            >
              <p>View Results</p>
              <div className="arrow-right-icon">
                <KeyboardArrowRightIcon />
              </div>
            </Button>

            <AnimateHeight duration={400} height={resultsHeight}>
              {loadingResults ? (
                <>
                  <div className="flex justify-center mt-4">
                    <CircularProgress color="primary" size={30} />
                  </div>
                </>
              ) : (
                <div className="apari-table-content">
                  <MatUiStyledTable>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Timestamp</TableCell>
                          <TableCell>URL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {results?.map((result: any) => (
                          <TableRow key={result.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>{timestamp}</TableCell>
                            <TableCell>
                              <p>{result.webhook_config.url}</p>
                              <p className={result.response.message === 'Success' ? 'text-green-600' : 'text-red-500'}>
                                {result.response.message}
                              </p>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </MatUiStyledTable>
                  <div className="w-full flex justify-end my-1">
                    <Tooltip title="Refresh Webhook Results">
                      <IconButton
                        id="webhook-configuration__results-refresh-btn"
                        aria-label="Refresh Webhook Results"
                        color="default"
                        onClick={getWebhookResults}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </AnimateHeight>

            <form onSubmit={handleWebhookConfigSubmit}>
              <div className="flex items-center mt-4">
                <h3 className="apari__subheading">Options</h3>
                <p className="apari__regular pl-2">*Values are calculated in hours</p>
              </div>
              <div className="flex items-center w-full">
                <label className="font-bold" htmlFor="webhook-configuration__edit-config--retries">
                  Number of Retries:
                </label>
                <div className="p-3">
                  <input
                    id="webhook-configuration__edit-config--retries"
                    className="border-black border-b focus:outline-none"
                    type="text"
                    name="webhook-configuration__edit-config--retries"
                    defaultValue={noOfRetries.toString()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNoOfRetries(e.target.value)}
                  />
                </div>
                <label className="font-bold" htmlFor="webhook-configuration__edit-config--retry-delay">
                  Retry Delay:
                </label>
                <div className="p-3">
                  <input
                    id="webhook-configuration__edit-config--retry-delay"
                    className="border-black border-b focus:outline-none"
                    type="text"
                    name="webhook-configuration__edit-config--retry-delay"
                    defaultValue={delay.toString()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDelay(e.target.value)}
                  />
                </div>
              </div>
              <h3 className="apari__subheading">Extra Headers</h3>
              <div className="mb-8">
                <MatUiStyledTable>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {extraHeaders &&
                        extraHeaders.map((extraHeader: any) => {
                          return (
                            <TableRow key={extraHeader.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>{extraHeader.name}</TableCell>
                              <TableCell>
                                <div className="flex justify-between items-center">
                                  {extraHeader.value}
                                  <CloseIcon
                                    id="webhook-configuration__edit-config-extra-headers--remove"
                                    className="hover:cursor-pointer"
                                    onClick={() => handleRemoveRow(extraHeader)}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </MatUiStyledTable>
                <div className="w-full flex justify-end my-1">
                  <Tooltip title="Add Extra Header">
                    <IconButton
                      id="webhook-configuration__edit-config-extra-headers--add"
                      aria-label="add"
                      color="default"
                      onClick={handleAddHeadersModal}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <h3 className="apari__subheading">Reports</h3>
                <FormGroup id="webhook-configuration__edit-config--reports-checkbox-group" className="checkbox">
                  {reportList &&
                    reportList.reports.map((report: any, index: number) => {
                      return (
                        <div key={`${index}`}>
                          <FormControlLabel
                            id={`webhook-configuration__edit-config--reports-checkbox-group--${report}`}
                            control={
                              <Checkbox
                                size="small"
                                sx={{
                                  [`&, &.${checkboxClasses.checked}`]: {
                                    color: '#673ffa',
                                  },
                                }}
                                onChange={(e: any) => handleChange(e)}
                                value={report}
                                defaultChecked={row.reports.includes(report)}
                              />
                            }
                            label={report}
                          />
                        </div>
                      );
                    })}
                </FormGroup>
                <FormGroup id="webhook-configuration__events-checkbox-group" className="checkbox">
                  <FormControlLabel
                    id={`webhook-configuration__events-checkbox-group--created`}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: '#673ffa',
                          },
                        }}
                        onChange={(e: any) => handleEventChange(e)}
                        value={'session.created'}
                        defaultChecked={row.events && row.events.includes('session.created')}
                      />
                    }
                    label={'Session: created'}
                  />
                  <FormControlLabel
                    id={`webhook-configuration__events-checkbox-group--deleted`}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: '#673ffa',
                          },
                        }}
                        onChange={(e: any) => handleEventChange(e)}
                        value={'session.closed'}
                        defaultChecked={row.events && row.events.includes('session.closed')}
                      />
                    }
                    label={'Session: closed'}
                  />
                </FormGroup>

                <div className="flex items-center gap-5 mt-4">
                  <h3 className="apari__subheading">Enabled: </h3>

                  <div
                    id="webhook-configuration__edit-config--enabled-toggle"
                    className="webhook-configuration__edit-config--enabled-toggle-switch hover: cursor-pointer"
                    onClick={() => setEnabled((prev: boolean) => !prev)}
                  >
                    <Toggle
                      toggleStyle={
                        enabled
                          ? { backgroundColor: '#673FFA', height: '22px', width: '65px' }
                          : { backgroundColor: '#cdcccc', height: '22px', width: '65px' }
                      }
                      innerRectangleStyle={enabled ? { right: '2px' } : { right: '33px' }}
                      textStyle={enabled ? { left: 0 } : { right: 0 }}
                      display={false}
                    />
                  </div>

                  {/* <FormGroup id="webhook-configuration__edit-config--enabled-checkbox-group" className="checkbox">
                    <FormControlLabel
                      id={`webhook-configuration__edit-config--enabled-checkbox-group--checkbox`}
                      control={
                        <Checkbox
                          size="small"
                          sx={{
                            [`&, &.${checkboxClasses.checked}`]: {
                              color: '#673ffa',
                            },
                          }}
                          onChange={() => setEnabled((prev: boolean) => !prev)}
                          value={enabled}
                          defaultChecked={row.active}
                        />
                      }
                      label={enabled}
                    />
                  </FormGroup> */}
                </div>

                <div className="mt-6">
                  <ApariPrimaryButton id="webhook-configuration__edit-config--save-btn" text="Save" type="submit" />
                </div>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={addRowModal}
        onClose={() => {
          setAddRowModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={addRowModal}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Extra Header</h2>
              <CloseIcon
                id="webhook-configuration__edit-config--extra-headers-close"
                className="hover:cursor-pointer"
                onClick={handleCloseHeadersModal}
              />
            </div>

            <form onSubmit={handleExtraHeadersSubmit}>
              <TextField
                id="webhook-configuration__edit-config--extra-headers-name"
                fullWidth
                margin="normal"
                type="text"
                label="Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
                required
              />
              <TextField
                id="webhook-configuration__edit-config--extra-headers-value"
                fullWidth
                margin="normal"
                type="text"
                label="Value"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
                required
              />

              <div className="mt-6">
                <ApariPrimaryButton
                  id="webhook-configuration__edit-config--extra-headers-save"
                  text="Save"
                  type="submit"
                />
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={showConfirmModal}>
          <Box sx={confirmStyle}>
            <h3 className="apari__subheading">Are you sure?</h3>
            <p className="text-sm">
              Click yes if you want to continue deleting the webhook <span className="font-bold">{row.url}</span>
            </p>
            <div className="mt-6 flex gap-4">
              <ApariSecondaryButton
                id="webhook-configuration__yes-btn"
                text="Yes"
                type="button"
                onClick={handleDeleteWebhookConfig}
              />
              <ApariPrimaryButton
                id="webhook-configuration__back-btn"
                text="Cancel"
                type="button"
                onClick={() => {
                  setShowConfirmModal(false);
                }}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default WebhooksTableRow;
