import React, { useContext, useEffect, useState } from 'react';

import { WebhookConfigurationsApi } from '@mtt/admin-api';
import { LinearProgress, Stack } from '@mui/material';
import AppContext from 'AppContext';
import ApariWebhooksTable from 'components/ApariWebhooksTable';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import { useNavigate } from 'react-router-dom';
import setupAxios from 'services/axios';

const Webhooks = () => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [reportList, setReportList] = useState<any>(undefined);

  const { height, setHeight } = useContext(AppContext);

  const webhookConfigurationsApi = new WebhookConfigurationsApi();
  const navigate = useNavigate();

  useEffect(() => {
    setupAxios();

    (async () => {
      setHeight(height === 0 ? 'auto' : null), getWebhookConfigurations();
    })();

    if (redirect) {
      navigate('/login');
    }

    fetchReports();
  }, [redirect]);

  const getWebhookConfigurations = async () => {
    webhookConfigurationsApi
      .webhookConfigurationControllerGetWebhookConfigurations()
      .then((res: any) => {
        setRows(res.data);
        setLoading(false);
      })
      .catch(() => {
        setRedirect(true);
      });
  };

  const fetchReports = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_ADMIN_API_BASE_URL}/protected/webhook-configurations/reports`,
      {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      },
    );

    const content = await response.json();

    if (response.ok) {
      setReportList(content);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Stack sx={{ width: '100%', color: '#673FFA' }} spacing={4}>
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
          </Stack>
        </>
      ) : (
        <>
          <h1 className="apari__heading mb-4">Webhooks</h1>
          <ApariWebhooksTable
            rows={rows}
            open={open}
            setOpen={setOpen}
            getWebhookConfigurations={getWebhookConfigurations}
            reportList={reportList}
          />

          <div className="w-64 mt-4">
            <ApariPrimaryButton
              id="webhook-configuration__add-btn"
              text="Add Webhook Configuration"
              type="button"
              onClick={() => {
                setOpen(true);
                fetchReports();
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Webhooks;
