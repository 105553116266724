import React, { SetStateAction, useContext, useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import IpWhitelistTableRow from 'components/IpWhitelistTableRow';
import { Box, Fade, Modal, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormField from 'components/UI/FormField';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import setupAxios from 'services/axios';
import { IpWhitelistingApi } from '@mtt/admin-api';
import MatUiStyledTable from 'components/UI/MatUiStyledTable';
import AppContext from 'AppContext';

const ApariIpWhitelistTable = ({
  rows,
  open,
  setOpen,
  user,
  getIps,
}: {
  rows: any;
  open: boolean;
  setOpen: any;
  user: any;
  getIps: any;
}) => {
  const [ip, setIp] = useState<null | string>(null);
  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const ipWhitelistingApi = new IpWhitelistingApi();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    ipWhitelistingApi
      .ipWhitelistingControllerCreate({ ip })
      .then((res: any) => {
        if (res.data.error) {
          setHelperText(res.data.message);
          setIsError(true);
        }

        setOpen(false);
        setIsError(false);
        setHelperText(null);
        setShowSuccessAlert(true);
        setMessage('Ip has been added.');
        getIps();
      })
      .catch((err) => {
        setHelperText(err.response.data.message);
        setIsError(true);
      });
  };

  return (
    <div className="apari-table-content">
      <MatUiStyledTable>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>IP</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} id={'0.0.0.0/0'}>
                  <TableCell>0.0.0.0/0</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </>
            ) : (
              <>
                {rows.map((row: any) => (
                  <IpWhitelistTableRow key={row.id} row={row} getIps={getIps} />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </MatUiStyledTable>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Add IP</h2>
              <CloseIcon className="hover:cursor-pointer" onClick={() => setOpen(false)} />
            </div>

            <form action="" onSubmit={handleSubmit}>
              <TextField
                id="ip-whitelisting__ip"
                fullWidth
                margin="normal"
                type="text"
                label="IP"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIp(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
              />

              <div className="mt-6">
                <ApariPrimaryButton id="ip-whitelisting__save-btn" text="Save" type="submit" />
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ApariIpWhitelistTable;
