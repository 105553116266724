import React, { useContext, useEffect, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import setupAxios from 'services/axios';
import ApariDropdown from '../ApariDropdown';
import { UsersApi } from '@mtt/admin-api';
import AppContext from 'AppContext';

const UsersTableRow = ({ row, getUsers, isOwner }: { row: any; getUsers: any; isOwner: boolean }) => {
  const [active, setActive] = useState(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const usersApi = new UsersApi();

  useEffect(() => {
    setupAxios();
  }, []);

  const handleDeleteUser = async () => {
    usersApi
      .adminUiUsersControllerDelete(row.id)
      .then(() => {
        setShowSuccessAlert(true);
        setMessage('User has been deleted.');
        getUsers();
      })
      .catch((err: any) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{row.mail}</TableCell>
        <TableCell>{row.displayName}</TableCell>
        <TableCell className="relative flex justify-between items-center">
          Active
          {isOwner ? (
            <ApariDropdown
              active={active}
              setActive={setActive}
              id={`apari-dropdown__btn--${row.mail.toLowerCase().replaceAll(/[@.]/g, '-')}`}
            >
              <div className="apari-dropdown__menu">
                <ul className="apari-dropdown__menu--list">
                  <li
                    id={`users__remove--${row.mail.toLowerCase().replaceAll(/[@.]/g, '-')}`}
                    className="apari-dropdown__menu--list-item"
                    onClick={handleDeleteUser}
                  >
                    Remove User
                  </li>
                </ul>
              </div>
            </ApariDropdown>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  );
};

export default UsersTableRow;
