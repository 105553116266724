import { LinearProgress, Stack, TextField } from '@mui/material';
import AppContext from 'AppContext';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './UpdatePassword.scss';

const UpdatePassword = () => {
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const { setShow, setShowSuccessAlert, setShowErrorAlert, setMessage }: any = useContext(AppContext);

  const info = searchParams.get('info');
  const token = searchParams.get('token');

  const navigate = useNavigate();

  useEffect(() => {
    setShow(false);

    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/public/invitation/verify-link`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          info,
          token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if ((data.statusCode && data.statusCode === 404) || (data.statusCode && data.statusCode === 401)) {
            navigate('/invite-expired');
          }
        })
        .catch((err) => console.log(err));
    };

    fetchData().catch(console.error);
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await fetch(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/public/invitation/activate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        info,
        token,
        password,
        confirmPassword: passwordConfirmation,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.statusCode && data.statusCode === 400) {
          setHelperText(data.message);
          setIsError(true);
        } else if (data.statusCode && data.statusCode === 409) {
          setMessage(data.message);
          setShowErrorAlert(true);
        } else if (!data.statusCode) {
          setMessage('Password has been updated. Please login using your new password.');
          setShowSuccessAlert(true);
          navigate('/login');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="update-password-container">
      <div className="update-password">
        <h1 className="apari__heading">Hello!</h1>

        <p className="apari__regular">Please update your password to access your account!</p>
        <form onSubmit={handleSubmit}>
          <div className="my-4">
            <TextField
              id="update__password"
              fullWidth
              margin="normal"
              type="password"
              label="Password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              variant="standard"
              error={isError}
              helperText={helperText}
            />
            <TextField
              id="update__password-confirmation"
              fullWidth
              margin="normal"
              type="password"
              label="Confirm Password"
              value={passwordConfirmation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirmation(e.target.value)}
              variant="standard"
              error={isError}
              helperText={helperText}
            />
          </div>
          <ApariPrimaryButton id="update__password-btn" type="submit" text="Update Password" />
          {loading && (
            <Stack sx={{ width: '100%', color: '#673FFA' }} className="mt-4">
              <LinearProgress color="inherit" />
            </Stack>
          )}
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
