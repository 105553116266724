import React from 'react';
import './Toggle.scss';

import LockIcon from '@mui/icons-material/Lock';
import Tooltip from '@mui/material/Tooltip';

const Toggle = ({
  text,
  toggleStyle,
  innerRectangleStyle,
  textStyle,
  display,
}: {
  text?: string;
  toggleStyle?: any;
  innerRectangleStyle?: any;
  textStyle?: any;
  display: boolean;
}) => {
  return (
    <>
      <div className="flex items-center">
        <div className="toggle" style={toggleStyle}>
          <div className="toggle__inner-rectangle" style={innerRectangleStyle}></div>
          <span className="toggle__text" style={textStyle}>
            {text}
          </span>
        </div>
        {display ? (
          <div className="toggle-lock-icon">
            <Tooltip title="Only APARI administrators can modify ownership" placement="top">
              <LockIcon />
            </Tooltip>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Toggle;
