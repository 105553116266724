import { styled, TableContainer } from '@mui/material';
import React from 'react';

const Table = styled(TableContainer)(() => ({
  overflowX: 'unset',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: '4px',
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  width: '100%',
}));

const MatUiStyledTable = ({ children }: { children: any }) => {
  return <Table>{children}</Table>;
};

export default MatUiStyledTable;
