import React, { SetStateAction, useContext, useEffect, useState } from 'react';

import './Login.scss';

import { LinearProgress, Stack, TextField } from '@mui/material';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import { useNavigate } from 'react-router-dom';

import setupAxios from 'services/axios';
import { AuthApi } from '@mtt/admin-api';
import AppContext from 'AppContext';

const Login = ({ user, setCheckJwt }: { user: any; setCheckJwt: React.Dispatch<SetStateAction<boolean>> }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);

  const { setShow, setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const authApi = new AuthApi();
  const navigate = useNavigate();

  useEffect(() => {
    setShow(false);

    setupAxios();

    if (!user) return;

    const loggedInUser = localStorage.getItem('user');
    let userFromLocalStorage;
    if (loggedInUser) {
      userFromLocalStorage = JSON.parse(loggedInUser);
    } else {
      navigate('/login');
    }

    if (loggedInUser && userFromLocalStorage.isSecondFactorAuthenticated) {
      navigate('/dashboard');
    }

    if (redirect) {
      navigate('/two-factor-setup');
    }
  }, [redirect, user]);

  const handleLogin = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    await authApi
      .authControllerLogin({ email: email, password: password })
      .then((res: any) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setShowErrorAlert(true);
          return;
        }

        setLoading(false);
        setShowSuccessAlert(true);
        setMessage('You have logged in successfully!');
        setCheckJwt(true);
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setHelperText(err.response.data.message);
        setIsError(true);
      });
  };

  // const toggleShowPassword = () => {
  //   setShowPassword((prev) => !prev);
  //   const x = document.getElementById('login__email') as HTMLInputElement;
  //   if (x.type === 'password') {
  //     x.type = 'text';
  //   } else {
  //     x.type = 'password';
  //   }
  // };

  useEffect(() => {
    if (password.length > 0) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  }, [password]);

  return (
    <>
      <div className="login-container">
        <div className="login">
          <h1 className="apari__heading">Welcome!</h1>
          <p className="apari__regular">Sign in with your details below!</p>
          <form onSubmit={handleLogin}>
            <div className="w-72 my-4">
              <TextField
                id="login__email"
                fullWidth
                margin="normal"
                type="email"
                label="Email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
              />
              <TextField
                id="login__password"
                fullWidth
                margin="normal"
                type="password"
                label="Password"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
              />
            </div>
            {/* <div className="mt-1 mb-6">
              <NavLink to="/" className="login__link-text">
                Reset password?
              </NavLink>
            </div> */}
            <ApariPrimaryButton id="login__sign-in-btn" type="submit" text="Sign in" />
            {loading && (
              <Stack sx={{ width: '100%', color: '#673FFA' }} className="mt-4">
                <LinearProgress color="inherit" />
              </Stack>
            )}
          </form>
          {/* <p className="login__text text-center my-4">OR</p>
          <NavLink to="/" className="login__link-text">
            Sign up now for free
          </NavLink> */}
        </div>
      </div>
    </>
  );
};

export default Login;
