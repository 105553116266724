import React, { SetStateAction, useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Fade, Modal, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import RedirectUrlsTableRow from 'components/RedirectUrlsTableRow';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import setupAxios from 'services/axios';
import { RedirectUrlsApi } from '@mtt/admin-api';
import MatUiStyledTable from 'components/UI/MatUiStyledTable';
import AppContext from 'AppContext';

const ApariRedirectUrlsTable = ({
  rows,
  open,
  setOpen,
  getRedirectUrls,
}: {
  rows: any;
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  getRedirectUrls: any;
}) => {
  const [url, setUrl] = useState<null | string>(null);
  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);

  const { setShowSuccessAlert, setMessage } = useContext(AppContext);

  const redirectUrlsApi = new RedirectUrlsApi();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    redirectUrlsApi
      .redirectControllerCreate({ url })
      .then(() => {
        setOpen(false);
        setIsError(false);
        setHelperText(null);
        setShowSuccessAlert(true);
        setMessage('Redirect URL has been added.');
        getRedirectUrls();
      })
      .catch((err) => {
        setHelperText(err.response.data.message);
        setIsError(true);
      });
  };

  return (
    <div className="apari-table-content">
      <MatUiStyledTable>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <RedirectUrlsTableRow key={row.id} row={row} getRedirectUrls={getRedirectUrls} />
            ))}
          </TableBody>
        </Table>
      </MatUiStyledTable>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Add Redirect URL</h2>
              <CloseIcon className="hover:cursor-pointer" onClick={() => setOpen(false)} />
            </div>

            <form onSubmit={handleSubmit}>
              <TextField
                id="redirect-urls__url"
                fullWidth
                margin="normal"
                type="text"
                label="Redirect URL"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
              />

              <div className="mt-6">
                <ApariPrimaryButton id="redirect-urls__save-btn" text="Save" type="submit" />
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ApariRedirectUrlsTable;
