import React, { createContext, useState } from 'react';
import { Height } from 'react-animate-height';

interface AppContextInterface {
  showSuccessAlert: boolean;
  setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showErrorAlert: boolean;
  setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
  message: string | null;
  setMessage: React.Dispatch<React.SetStateAction<string | null>>;
  height: string | number | null | Height;
  setHeight: React.Dispatch<React.SetStateAction<string | number | null | Height>>;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props {
  children: React.ReactNode;
}

const AppContext = createContext({} as AppContextInterface);

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [message, setMessage] = useState<null | string>(null);
  const [height, setHeight] = useState<string | number | null>(0);
  const [show, setShow] = useState(false);

  return (
    <AppContext.Provider
      value={{
        showSuccessAlert,
        setShowSuccessAlert,
        showErrorAlert,
        setShowErrorAlert,
        message,
        setMessage,
        height,
        setHeight,
        show,
        setShow,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
