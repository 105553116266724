import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './TwoFactorRecoveryKey.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { LinearProgress, Stack, TextField } from '@mui/material';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import Cookies from 'js-cookie';
import setupAxios from 'services/axios';
import { Class2faApi } from '@mtt/admin-api';
import AppContext from 'AppContext';

const TwoFactorRecoveryKey = ({
  user,
  setCheckJwt,
}: {
  user: any;
  setCheckJwt: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();

  const [redirect, setRedirect] = useState(false);
  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const twoFactorApi = new Class2faApi();

  useEffect(() => {
    setupAxios();

    if (Cookies.get('JWT') === undefined) {
      navigate('/login');
    }

    if (redirect) {
      navigate('/dashboard');
    }
  }, [redirect]);

  const [twoFactorAuthenticationCode, setTwoFactorAuthenticationCode] = useState('');

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    setLoading(true);

    if (user.secondFactorReady) {
      twoFactorApi
        .twoFactorAuthenticationControllerAuthenticate({
          twoFactorAuthenticationCode,
        })
        .then((res: any) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setShowErrorAlert(true);
            return;
          }

          setLoading(false);
          setShowSuccessAlert(true);
          setMessage('Two-factor authentication successful');
          setRedirect(true);
          setCheckJwt(true);
        })
        .catch((err) => {
          setLoading(false);
          setHelperText(err.response.data.message);
          setIsError(true);
        });
    } else {
      twoFactorApi
        .twoFactorAuthenticationControllerTurnOnTwoFactorAuthentication({ twoFactorAuthenticationCode })
        .then((res: any) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setShowErrorAlert(true);
            return;
          }

          setShowSuccessAlert(true);
          setMessage('Two-factor setup complete');
          setRedirect(true);
          setCheckJwt(true);
        })
        .catch((err) => {
          setHelperText(err.response.data.message);
          setIsError(true);
        });
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-4">
            {user && !user.secondFactorReady && (
              <NavLink id="two-factor__back-link" to="/two-factor-setup" className="pr-2">
                <ArrowBackIcon className="transition-colors ease-in-out rounded-full duration-400 p-0.5 hover:cursor-pointer hover:bg-gray-200" />
              </NavLink>
            )}

            <h1 className="apari__heading">Authentication</h1>
          </div>
          <h2 className="apari__subheading">Please enter the code generated by your app</h2>
          <div className="mt-4">
            <TextField
              id="two-factor__code"
              fullWidth
              margin="normal"
              type="text"
              label="Two Factor Code"
              value={twoFactorAuthenticationCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTwoFactorAuthenticationCode(e.target.value)}
              variant="standard"
              error={isError}
              helperText={helperText}
            />
          </div>
          <div className="w-full mt-4">
            <ApariPrimaryButton
              id="two-factor__login-btn"
              type="submit"
              text={`${user && user.secondFactorReady ? 'Login' : 'Activate'}`}
            />
            {loading && (
              <Stack sx={{ width: '100%', color: '#673FFA' }} className="mt-4">
                <LinearProgress color="inherit" />
              </Stack>
            )}
            <div className="text-center my-2">
              <NavLink id="two-factor__cancel-link" to="/profile" className="two-factor__link-text">
                Cancel
              </NavLink>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default TwoFactorRecoveryKey;
