import React from 'react';
import PropTypes from 'prop-types';
import './FormField.scss';

const FormField = ({
  type,
  label,
  value,
  icon,
  onClick,
  id,
  readOnly,
  onChange,
  showIcon,
  placeholder,
}: {
  type: string;
  label: string;
  value?: string;
  icon: any;
  onClick: any;
  id?: string;
  readOnly?: boolean;
  onChange: any;
  showIcon?: boolean;
  placeholder?: string;
}) => {
  return (
    <>
      <div className="form-field">
        <label className="form-field__label" htmlFor={label.toLowerCase()}>
          {label}
        </label>
        <div className="flex justify-between relative">
          <input
            className="form-field__input"
            type={type}
            defaultValue={value}
            id={id}
            readOnly={readOnly}
            onChange={onChange}
            placeholder={placeholder}
          />
          {icon ? (
            <button type="button" className="form-field__icon" onClick={onClick}>
              {showIcon ? icon : null}
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.any,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.any,
  placeholder: PropTypes.string,
};

export default FormField;
