import React, { useContext, useEffect, useState } from 'react';

import { IpWhitelistingApi } from '@mtt/admin-api';
import { LinearProgress, Stack } from '@mui/material';
import AppContext from 'AppContext';
import ApariIpWhitelistTable from 'components/ApariIpWhitelistTable';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import { useNavigate } from 'react-router-dom';
import setupAxios from 'services/axios';

const IpWhitelist = ({ user }: { user: any }) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const { height, setHeight } = useContext(AppContext);

  const ipWhitelistingApi = new IpWhitelistingApi();
  const navigate = useNavigate();

  useEffect(() => {
    setupAxios();

    (async () => {
      setHeight(height === 0 ? 'auto' : null), getIps();
    })();

    if (redirect) {
      navigate('/login');
    }
  }, [redirect]);

  const getIps = () => {
    ipWhitelistingApi
      .ipWhitelistingControllerGetIps()
      .then((res: any) => {
        setRows(res.data);
        setLoading(false);
      })
      .catch(() => {
        setRedirect(true);
      });
  };

  return (
    <>
      <h1 className="apari__heading mb-4">IP Whitelist</h1>

      {loading ? (
        <>
          <Stack sx={{ width: '100%', color: '#673FFA' }} spacing={4}>
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
          </Stack>
        </>
      ) : (
        <>
          <ApariIpWhitelistTable rows={rows} open={open} setOpen={setOpen} user={user} getIps={getIps} />

          {rows.length === 0 && (
            <p className="apari__regular mt-4">Please add an IP address to enable IP whitelisting.</p>
          )}

          <div className="w-48 mt-4">
            <ApariPrimaryButton
              id="ip-whitelisting__add-btn"
              text="Add IP"
              type="button"
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default IpWhitelist;
