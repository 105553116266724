import React, { SetStateAction, useContext, useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ClientSetupTableRow from 'components/ClientSetupTableRow';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  checkboxClasses,
  IconButton,
  Tooltip,
  Button,
  Fade,
  CircularProgress,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import ApariSecondaryButton from 'components/UI/ApariSecondaryButton';
import setupAxios from 'services/axios';
import { ClientApi } from '@mtt/admin-api';
import MatUiStyledTable from 'components/UI/MatUiStyledTable';
import AppContext from 'AppContext';

const ApariClientSetupTable = ({
  rows,
  open,
  setOpen,
  getClients,
}: {
  rows: any;
  open: boolean;
  setOpen: any;
  getClients: any;
}) => {
  const [secret, setSecret] = useState('');
  const [clientId, setClientId] = useState(undefined);
  const [showSaveBtn, setShowSaveBtn] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showSecretBtn, setShowSecretBtn] = useState<boolean>(true);
  const [name, setName] = useState('');
  const [scopes, setScopes] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const clientApi = new ClientApi();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '80%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    await clientApi
      .clientControllerCreate({ name, scope: scopes })
      .then((res: any) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setShowErrorAlert(true);
          return;
        }

        setSecret(res.data.secret);
        setClientId(res.data.clientId);
        setShowDetails(true);
        setShowSaveBtn(true);
        setShowSecretBtn(false);
        getClients();
        setLoading(false);
        setMessage('Client has been created');
        setShowSuccessAlert(true);
      })
      .catch((err) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
        setLoading(false);
      });
  };

  const handleChange = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setScopes([...scopes, value]);
    } else {
      setScopes(scopes.filter((elem: any) => elem !== value));
    }
  };

  const handleSave = () => {
    getClients();
    setOpen(false);
    setSecret('');
    setName('');
    setShowSaveBtn(false);
    setScopes([]);
    setShowSecretBtn(true);
    setShowDetails(false);
    setShowConfirmModal(false);
  };

  return (
    <div className="apari-table-content">
      <MatUiStyledTable>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Client Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.clients.map((row: any) => (
              <ClientSetupTableRow
                key={row.id}
                row={row}
                setOpen={setOpen}
                getClients={getClients}
                scopes={scopes}
                setScopes={setScopes}
              />
            ))}
          </TableBody>
        </Table>
      </MatUiStyledTable>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Create new client</h2>
              {!showSaveBtn && (
                <CloseIcon
                  id="client-setup__icon-btn"
                  className="hover:cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              )}
            </div>

            <form onSubmit={handleSubmit}>
              <div className="py-3">
                <TextField
                  fullWidth
                  id="client-setup__name"
                  type="text"
                  label="Client name"
                  variant="standard"
                  value={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                />
              </div>
              {/* <FormField
                id="client-setup__name"
                type="text"
                label="Client name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              /> */}
              <div className="mb-6">
                <h3 className="apari__subheading">Scopes</h3>
                <FormGroup id="client-setup__max-scope-checkbox-group" className="checkbox">
                  {rows.company.maxScope.map((scope: string, index: number) => {
                    return (
                      <div key={`${rows.company.id + index}`}>
                        <FormControlLabel
                          id={`client-setup__max-scope-checkbox-group--${scope.replaceAll(':', '_')}`}
                          control={
                            <Checkbox
                              size="small"
                              sx={{
                                [`&, &.${checkboxClasses.checked}`]: {
                                  color: '#673ffa',
                                },
                              }}
                              onChange={(e: any) => handleChange(e)}
                              value={`${scope}`}
                            />
                          }
                          label={`${scope}`}
                        />
                      </div>
                    );
                  })}
                </FormGroup>
              </div>
              <div className="mb-8">
                <h3 className="apari__subheading">Client Details</h3>
                {showDetails ? (
                  <div className="flex flex-col">
                    <div>
                      <div className="flex items-center mt-4">
                        <h3 className="apari__regular">Client Id:</h3>
                        <p className="apari__regular text-xs font-semibold pl-2">{clientId}</p>
                      </div>

                      <div className="flex flex-col mt-2">
                        <h3>Client Secret: </h3>
                        <div className="flex items-center">
                          <CopyToClipboard
                            text={secret}
                            onCopy={() => {
                              setShowSuccessAlert(true);
                              setMessage('Copied to clipboard');
                            }}
                          >
                            <Tooltip title="Copy Secret">
                              <IconButton
                                id="client-setup__copy-secret"
                                className="hover:cursor-pointer"
                                aria-label="Copy Secret Button"
                                size="small"
                              >
                                <ContentCopyIcon className="p-1" />
                              </IconButton>
                            </Tooltip>
                          </CopyToClipboard>
                          <p id="client-setup__secret" className="apari__regular pl-2 text-xs font-semibold">
                            {secret}
                          </p>
                        </div>
                        <p className="text-sm font-bold mt-4">
                          Please make a note of this secret as it can only be viewed once. After closing this window,
                          you will not be able to view the secret again.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mb-6">
                    {loading ? (
                      <>
                        <div className="flex items-center">
                          <Button
                            id="client-setup__create-client-btn"
                            sx={{ color: '#000' }}
                            type="submit"
                            className="apari__secret-text font-semibold text-sm uppercase transition-colors duration-150 mt-2"
                            disabled
                          >
                            Create Client
                          </Button>
                          <CircularProgress color="primary" size={30} />
                        </div>
                      </>
                    ) : (
                      showSecretBtn && (
                        <Button
                          id="client-setup__create-client-btn"
                          sx={{ color: '#000' }}
                          type="submit"
                          className="apari__secret-text font-semibold text-sm uppercase transition-colors duration-150 mt-2"
                        >
                          Create Client
                        </Button>
                      )
                    )}
                  </div>
                )}
              </div>
              {showSaveBtn && (
                <div className="mt-6">
                  <ApariPrimaryButton
                    id="client-setup__save-client-btn"
                    text="Save"
                    type="button"
                    onClick={() => {
                      setShowConfirmModal(true);
                      setOpen(false);
                    }}
                  />
                </div>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={showConfirmModal}>
          <Box sx={style}>
            <h3 className="apari__subheading">Are you sure?</h3>
            <p className="text-sm">Have you taken a note of your secret?</p>
            <div className="mt-6 flex gap-4">
              <ApariSecondaryButton id="client-setup__yes-btn" text="Yes" type="button" onClick={handleSave} />
              <ApariPrimaryButton
                id="client-setup__back-btn"
                text="Go Back"
                type="button"
                onClick={() => {
                  setShowConfirmModal(false);
                  setOpen(true);
                }}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ApariClientSetupTable;
