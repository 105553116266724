import React, { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Checkbox,
  checkboxClasses,
  Fade,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { WebhookConfigurationsApi } from '@mtt/admin-api';
import AppContext from 'AppContext';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import MatUiStyledTable from 'components/UI/MatUiStyledTable';
import WebhooksTableRow from 'components/WebhooksTableRow';
import setupAxios from 'services/axios';

const ApariWebhooksTable = ({
  rows,
  open,
  setOpen,
  getWebhookConfigurations,
  reportList,
}: {
  rows: any;
  open: boolean;
  setOpen: any;
  getWebhookConfigurations: any;
  reportList: any;
}) => {
  const [url, setUrl] = useState<string>('');
  const [extraHeaders, setExtraHeaders] = useState<{ name: string; value: string }[]>([]);
  const [noOfRetries, setNoOfRetries] = useState('2');
  const [delay, setDelay] = useState('12');
  const [addRowModal, setAddRowModal] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [reports, setReports] = useState<Array<string>>([]);
  const [events, setEvents] = useState<Array<string>>(['session.created', 'session.closed']);

  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);

  const { setShowSuccessAlert, setMessage } = useContext(AppContext);

  const webhookConfigurationsApi = new WebhookConfigurationsApi();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    webhookConfigurationsApi
      .webhookConfigurationControllerCreate({
        url,
        maxRetries: parseInt(noOfRetries),
        retryDelay: parseInt(delay),
        extraHeaders,
        reports,
        events,
      })
      .then(() => {
        setOpen(false);
        setIsError(false);
        setHelperText(null);
        setShowSuccessAlert(true);
        setMessage('Webhook Configuration has been added.');
        setUrl('');
        setExtraHeaders([]);
        setReports([]);
        setEvents([]);
        setNoOfRetries('2');
        setDelay('12');
        getWebhookConfigurations();
      })
      .catch((err) => {
        setHelperText(err.response.data.message);
        setIsError(true);
      });
  };

  const handleRemoveRow = (header: any) => {
    setExtraHeaders(extraHeaders.filter((elem: any) => elem !== header));
  };

  const handleAddHeadersModal = () => {
    setOpen(false);
    setAddRowModal(true);
  };

  const handleCloseHeadersModal = () => {
    setAddRowModal(false);
    setOpen(true);
  };

  const handleExtraHeadersSubmit = (e: any) => {
    e.preventDefault();

    setExtraHeaders((prevExtraHeaders: { name: string; value: string }[]) =>
      prevExtraHeaders ? [...prevExtraHeaders, { name, value }] : [{ name, value }],
    );
    setOpen(true);
    setAddRowModal(false);
  };

  const handleChange = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setReports([...reports, value]);
    } else {
      setReports(reports.filter((elem: any) => elem !== value));
    }
  };

  const handleEventChange = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setEvents([...events, value]);
    } else {
      setEvents(events.filter((elem: any) => elem !== value));
    }
  };

  return (
    <div className="apari-table-content">
      <MatUiStyledTable>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              {/* <TableCell>Status</TableCell> */}
              <TableCell>Enabled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <WebhooksTableRow
                key={row.id}
                row={row}
                getWebhookConfigurations={getWebhookConfigurations}
                reportList={reportList}
              />
            ))}
          </TableBody>
        </Table>
      </MatUiStyledTable>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <h2 className="apari__subheading mb-3">Create Webhook</h2>
            <h3 className="apari__subheading">URL</h3>
            <TextField
              id="webhook-configuration__url"
              sx={{ marginTop: 0 }}
              fullWidth
              margin="normal"
              type="text"
              label="Webhook Configuration URL"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)}
              variant="standard"
              error={isError}
              helperText={helperText}
              value={url ? url : ''}
            />

            <form onSubmit={handleSubmit}>
              <div className="flex items-center">
                <h3 className="apari__subheading">Options</h3>
                <p className="apari__regular pl-2">*Values are calculated in hours</p>
              </div>
              <div className="flex items-center w-full">
                <label className="font-bold" htmlFor="webhook-configuration__retries">
                  Number of Retries:
                </label>
                <div className="p-3">
                  <input
                    id="webhook-configuration__retries"
                    className="border-black border-b focus:outline-none"
                    type="text"
                    name="webhook-configuration__retries"
                    defaultValue={noOfRetries.toString()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNoOfRetries(e.target.value)}
                  />
                </div>
                <label className="font-bold" htmlFor="webhook-configuration__retry-delay">
                  Retry Delay:
                </label>
                <div className="p-3">
                  <input
                    id="webhook-configuration__retry-delay"
                    className="border-black border-b focus:outline-none"
                    type="text"
                    name="webhook-configuration__retry-delay"
                    defaultValue={delay.toString()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDelay(e.target.value)}
                  />
                </div>
              </div>
              <h3 className="apari__subheading">Extra Headers</h3>
              <div className="mb-8">
                <MatUiStyledTable>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {extraHeaders &&
                        extraHeaders.map((extraHeader: any) => {
                          return (
                            <TableRow key={extraHeader.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>{extraHeader.name}</TableCell>
                              <TableCell>
                                <div className="flex justify-between items-center">
                                  {extraHeader.value}
                                  <CloseIcon
                                    id="webhook-configuration__extra-headers--remove"
                                    className="hover:cursor-pointer"
                                    onClick={() => handleRemoveRow(extraHeader)}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </MatUiStyledTable>
                <div className="w-full flex justify-end my-1">
                  <Tooltip title="Add Extra Header">
                    <IconButton
                      id="webhook-configuration__extra-headers--add"
                      aria-label="add"
                      color="default"
                      onClick={handleAddHeadersModal}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <h3 className="apari__subheading">Events</h3>
                <FormGroup id="webhook-configuration__reports-checkbox-group" className="checkbox">
                  {reportList &&
                    reportList.reports.map((report: any, index: number) => {
                      return (
                        <div key={`${index}`}>
                          <FormControlLabel
                            id={`webhook-configuration__reports-checkbox-group--${report}`}
                            control={
                              <Checkbox
                                size="small"
                                sx={{
                                  [`&, &.${checkboxClasses.checked}`]: {
                                    color: '#673ffa',
                                  },
                                }}
                                onChange={(e: any) => handleChange(e)}
                                value={report}
                              />
                            }
                            label={`Report: ${report}`}
                          />
                        </div>
                      );
                    })}
                </FormGroup>
                <FormGroup id="webhook-configuration__events-checkbox-group" className="checkbox">
                  <FormControlLabel
                    id={`webhook-configuration__events-checkbox-group--created`}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: '#673ffa',
                          },
                        }}
                        onChange={(e: any) => handleEventChange(e)}
                        value={'session.created'}
                        defaultChecked={true}
                      />
                    }
                    label={'Session: created'}
                  />
                  <FormControlLabel
                    id={`webhook-configuration__sessions-checkbox-group--deleted`}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: '#673ffa',
                          },
                        }}
                        onChange={(e: any) => handleEventChange(e)}
                        value={'session.closed'}
                        defaultChecked={true}
                      />
                    }
                    label={'Session: closed'}
                  />
                </FormGroup>
                <div className="mt-6">
                  <ApariPrimaryButton id="webhook-configuration__save-btn" text="Save" type="submit" />
                </div>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={addRowModal}
        onClose={() => {
          setAddRowModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={addRowModal}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Extra Header</h2>
              <CloseIcon
                id="webhook-configuration__extra-headers--close"
                className="hover:cursor-pointer"
                onClick={handleCloseHeadersModal}
              />
            </div>

            <form onSubmit={handleExtraHeadersSubmit}>
              <TextField
                id="webhook-configuration__extra-headers--name"
                fullWidth
                margin="normal"
                type="text"
                label="Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
                required
              />
              <TextField
                id="webhook-configuration__extra-headers--value"
                fullWidth
                margin="normal"
                type="text"
                label="Value"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
                required
              />

              <div className="mt-6">
                <ApariPrimaryButton id="webhook-configuration__extra-headers--save" text="Save" type="submit" />
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ApariWebhooksTable;
