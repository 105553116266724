import React from 'react';

import { RadioButtonChecked } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

const ApariRadioButtonChecked = () => {
  return (
    <>
      <div className="relative">
        <RadioButtonChecked />
        <div className="absolute top-0 left-0 text-white scale-50">
          <CheckIcon />
        </div>
      </div>
    </>
  );
};

export default ApariRadioButtonChecked;
