import React, { useContext, useEffect, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { WebhookUrlsApi } from '@mtt/admin-api';
import ApariDropdown from 'components/UI/ApariDropdown';
import setupAxios from 'services/axios';
import AppContext from 'AppContext';

const WebhookHostsTableRow = ({ row, getWebhookHosts }: { row: any; getWebhookHosts: any }) => {
  const [active, setActive] = useState(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const webhookUrlsApi = new WebhookUrlsApi();

  useEffect(() => {
    setupAxios();
  });

  const handleDeleteWebhookHost = async () => {
    webhookUrlsApi
      .webhookUrlControllerDelete(row.id)
      .then(() => {
        setShowSuccessAlert(true);
        setMessage('Webhook Host has been deleted.');
        getWebhookHosts();
      })
      .catch((err: any) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} id={row.id}>
        <TableCell>{row.url}</TableCell>
        <TableCell className="relative flex justify-between items-center">
          Active
          <ApariDropdown
            active={active}
            setActive={setActive}
            id={`apari-dropdown__btn--${row.url.toLowerCase().replaceAll('.', '-')}`}
          >
            <div className="apari-dropdown__menu">
              <ul className="apari-dropdown__menu--list">
                <li
                  id={`webhook-hosts__remove--${row.url.toLowerCase().replaceAll('.', '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={handleDeleteWebhookHost}
                >
                  Remove URL
                </li>
              </ul>
            </div>
          </ApariDropdown>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WebhookHostsTableRow;
