import NavItem from 'components/UI/ListItem';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { NavLink } from 'react-router-dom';

import './Sidebar.scss';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DashboardIcon from '@mui/icons-material/Dashboard';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

import { CompanyApi } from '@mtt/admin-api';
import setupAxios from 'services/axios';
import apariLogo from './../../assets/APARI_Logo_white.svg';
import AppContext from 'AppContext';

const Sidebar = ({ user }: { user: any }) => {
  const ref: any = useRef();

  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');

  const { height, setHeight, show, setShow }: any = useContext(AppContext);

  const companyApi = new CompanyApi();

  useEffect(() => {
    setupAxios();

    if (user === null) {
      setShow(false);
      return;
    }

    if (show) {
      companyApi.companyControllerGetById().then((res: any) => {
        setCompanyName(res.data.displayName);
      });
    }
  }, [user, show]);

  const handleClickEvent = (e: any) => {
    if (open && ref.current && !ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (user === null) {
      return;
    }

    if (user.isSecondFactorAuthenticated) {
      setShow(true);
    } else {
      setShow(false);
    }

    document.addEventListener('mousedown', handleClickEvent);

    return () => {
      document.removeEventListener('mousedown', handleClickEvent);
    };
  }, [open, user]);

  return (
    <>
      {show && (
        <>
          <div id="sidebar__hamburger-icon" className="hamburger-icon flex items-center lg:hidden">
            <span onClick={() => setOpen((prev) => !prev)}>
              <MenuIcon />
            </span>
          </div>
          <div className={open ? 'sidebar active' : 'sidebar'} ref={ref}>
            <NavLink
              id="sidebar__apari-logo"
              to="/dashboard"
              className={process.env.REACT_APP_ENV === 'sandbox' ? 'logo-container' : 'logo-container mb-8'}
            >
              <img src={apariLogo} alt="Apari Logo" />
            </NavLink>
            {process.env.REACT_APP_ENV === 'sandbox' && (
              <div className="environment-container">
                <h1 className="apari__nav-heading">Sandbox Environment</h1>
              </div>
            )}
            <div className="items-container">
              <NavLink
                id="sidebar__dashboard-link"
                to="/dashboard"
                className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                onClick={(prev) => (prev ? setOpen(!prev) : null)}
              >
                <div className="py-2">
                  <NavItem
                    icon={<DashboardIcon />}
                    name="Dashboard"
                    iconStyle={{ color: '#fff', padding: '0 12px' }}
                    nameStyle={{ color: '#fff' }}
                  />
                </div>
              </NavLink>
              {/* <NavLink
            to="/reporting-and-statistics"
            className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
            onClick={(prev) => (prev ? setOpen(!prev) : null)}
          >
            <div className="py-2">
              <NavItem
                icon={<AssessmentIcon />}
                name="Reporting & statistics"
                iconStyle={{ color: '#fff', padding: '0 12px' }}
                nameStyle={{ color: '#fff' }}
              />
            </div>
          </NavLink> */}

              <div
                className="py-2 hover:cursor-pointer flex items-center disable-select"
                onClick={() => setHeight(height === 0 ? 'auto' : 0)}
              >
                <NavItem
                  icon={<SettingsIcon />}
                  name="Apari Connections"
                  iconStyle={{ color: '#fff', padding: '0 12px' }}
                  nameStyle={{ color: '#fff' }}
                />
                <div className={height === 0 ? 'sidebar-dropdown-arrow px-3' : 'sidebar-dropdown-arrow px-3 active'}>
                  <KeyboardArrowRightIcon data-dropdown-button />
                </div>
              </div>

              <AnimateHeight duration={400} height={height}>
                {/* <NavLink
                to="/mtls-certificates"
                className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                onClick={(prev) => (prev ? setOpen(!prev) : null)}
              >
                <div className="py-2 ml-6 border-l-2 border-solid border-white">
                  <NavItem
                    name="mTLS Certificates"
                    iconStyle={{ color: '#fff', padding: '0 12px' }}
                    nameStyle={{ color: '#fff' }}
                  />
                </div>
              </NavLink> */}
                <NavLink
                  id="sidebar__redirect-urls-link"
                  to="/redirect-urls"
                  className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                  onClick={(prev) => (prev ? setOpen(!prev) : null)}
                >
                  <div className="py-2 ml-6 border-l-2 border-solid border-white">
                    <NavItem
                      name="Redirect URLs"
                      iconStyle={{ color: '#fff', padding: '0 12px' }}
                      nameStyle={{ color: '#fff' }}
                    />
                  </div>
                </NavLink>
                <NavLink
                  id="sidebar__webhook-hosts-link"
                  to="/webhook-hosts"
                  className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                  onClick={(prev) => (prev ? setOpen(!prev) : null)}
                >
                  <div className="py-2 ml-6 border-l-2 border-solid border-white">
                    <NavItem
                      name="Webhook Hosts"
                      iconStyle={{ color: '#fff', padding: '0 12px' }}
                      nameStyle={{ color: '#fff' }}
                    />
                  </div>
                </NavLink>
                <NavLink
                  id="sidebar__webhooks-link"
                  to="/webhooks"
                  className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                  onClick={(prev) => (prev ? setOpen(!prev) : null)}
                >
                  <div className="py-2 ml-6 border-l-2 border-solid border-white">
                    <NavItem
                      name="Webhooks"
                      iconStyle={{ color: '#fff', padding: '0 12px' }}
                      nameStyle={{ color: '#fff' }}
                    />
                  </div>
                </NavLink>
                <NavLink
                  id="sidebar__ip-whitelist-link"
                  to="/ip-whitelist"
                  className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                  onClick={(prev) => (prev ? setOpen(!prev) : null)}
                >
                  <div className="py-2 ml-6 border-l-2 border-solid border-white">
                    <NavItem
                      name="IP Whitelist"
                      iconStyle={{ color: '#fff', padding: '0 12px' }}
                      nameStyle={{ color: '#fff' }}
                    />
                  </div>
                </NavLink>
              </AnimateHeight>

              <NavLink
                id="sidebar__users-link"
                to="/users"
                className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                onClick={(prev) => (prev ? setOpen(!prev) : null)}
              >
                <div className="py-2">
                  <NavItem
                    icon={<PeopleIcon />}
                    name="Users"
                    iconStyle={{ color: '#fff', padding: '0 12px' }}
                    nameStyle={{ color: '#fff' }}
                  />
                </div>
              </NavLink>
              <NavLink
                id="sidebar__client-setup-link"
                to="/client-setup"
                className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                onClick={(prev) => (prev ? setOpen(!prev) : null)}
              >
                <div className="py-2">
                  <NavItem
                    icon={<ArrowForwardIcon />}
                    name="Client Setup"
                    iconStyle={{ color: '#fff', padding: '0 12px' }}
                    nameStyle={{ color: '#fff' }}
                  />
                </div>
              </NavLink>
              <NavLink
                id="sidebar__profile-link"
                to="/profile"
                className={({ isActive }) => (isActive ? 'item item-active' : 'item')}
                onClick={(prev) => (prev ? setOpen(!prev) : null)}
              >
                <div className="py-2">
                  <NavItem
                    icon={<PersonIcon />}
                    name="Profile"
                    iconStyle={{ color: '#fff', padding: '0 12px' }}
                    nameStyle={{ color: '#fff' }}
                  />
                </div>
              </NavLink>
            </div>
            <div className="company-container">
              <h1 className="apari__nav-heading">{show ? companyName : null}</h1>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Sidebar;
