import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { UsersApi } from '@mtt/admin-api';
import { Fade, IconButton, LinearProgress, Stack, TextField, Tooltip } from '@mui/material';
import AppContext from 'AppContext';
import ApariUsersTable from 'components/ApariUsersTable';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import setupAxios from 'services/axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Users = () => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [displayName, setDisplayName] = useState<string | undefined>(undefined);
  const [invitationSent, setInvitationSent] = useState<boolean>(false);
  const [inviteLink, setInviteLink] = useState<string | undefined>(undefined);
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const { setHeight, setMessage, setShowErrorAlert, setShowSuccessAlert } = useContext(AppContext);

  const usersApi = new UsersApi();
  const navigate = useNavigate();

  useEffect(() => {
    setupAxios();

    if (Cookies.get('JWT') === undefined) {
      navigate('login');
    }

    const loggedInUser = localStorage.getItem('user');
    let userFromLocalStorage;
    if (loggedInUser) {
      userFromLocalStorage = JSON.parse(loggedInUser);
    } else {
      navigate('/login');
      return;
    }

    usersApi.adminUiUsersControllerGetUser(userFromLocalStorage.userId).then((res) => {
      setIsOwner(res.data.owner);
    });
  }, []);

  const getUsers = async () => {
    usersApi
      .adminUiUsersControllerIndex()
      .then((res: any) => {
        setRows(res.data);
        setLoading(false);
      })
      .catch(() => {
        setRedirect(true);
      });
  };

  useEffect(() => {
    (async () => {
      setHeight(0), getUsers();
    })();

    if (redirect) {
      navigate('/login');
    }
  }, [redirect]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setLoadingInvite(true);

    await fetch(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/protected/users/invite-user`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        mail: email,
        displayName,
      }),
    })
      .then((res: any) => res.json())
      .then((data: any) => {
        setLoadingInvite(false);

        if (data.link) {
          setMessage('Invite has been sent.');
          setShowSuccessAlert(true);
          setInvitationSent(true);
          setInviteLink(window.location.origin + '/administration' + data.link);
        } else {
          setMessage(data.message);
          setShowErrorAlert(true);
        }
      });
  };

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  return (
    <>
      <h1 className="apari__heading mb-4">Users</h1>
      {loading ? (
        <>
          <Stack sx={{ width: '100%', color: '#673FFA' }} spacing={4}>
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
          </Stack>
        </>
      ) : (
        <ApariUsersTable rows={rows} getUsers={getUsers} isOwner={isOwner} />
      )}
      {isOwner ? (
        <div className="w-36 mt-4">
          <ApariPrimaryButton
            id="users__invite-btn"
            text="Invite"
            type="button"
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      ) : null}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-3">
              <h2 className="apari__subheading">Invite a user</h2>
              <CloseIcon className="hover:cursor-pointer" onClick={() => setOpen(false)} />
            </div>

            <form onSubmit={handleSubmit}>
              <TextField
                id="users__invite--name"
                fullWidth
                margin="normal"
                type="text"
                label="Display Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDisplayName(e.target.value)}
                variant="standard"
              />
              <TextField
                id="users__invite--email"
                fullWidth
                margin="normal"
                type="email"
                label="Email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                variant="standard"
              />
              {invitationSent && (
                <>
                  <p className="apari__regular">
                    Congratulations, your invite has been sent!{' '}
                    <span className="font-bold">Pass this link to allow the user to activate their account</span>.
                    <br />
                  </p>
                  <div className="flex items-center gap-2">
                    <div className="overflow-x-scroll whitespace-nowrap">{inviteLink}</div>
                    <CopyToClipboard
                      text={inviteLink !== undefined ? inviteLink : ''}
                      onCopy={() => {
                        setShowSuccessAlert(true);
                        setMessage('Copied to clipboard');
                      }}
                    >
                      <Tooltip title="Copy Invite Link">
                        <IconButton id="users__copy-invite-link" aria-label="Copy Invite Link">
                          <ContentCopyIcon sx={{ padding: '2px' }} />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
                </>
              )}
              <div className="mt-6">
                <ApariPrimaryButton id="users__create-invite-btn" text="Create Invite" type="submit" />
              </div>
              {loadingInvite && (
                <Stack sx={{ width: '100%', color: '#673FFA' }} className="mt-4">
                  <LinearProgress color="inherit" />
                </Stack>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Users;
