declare const process: {
    env: {
        NODE_ENV: string;
        REACT_APP_ADMIN_API_BASE_URL: string;
    };
};

const GLOBAL = {
    NODE_ENV: process.env.NODE_ENV,
    ADMIN_API_BASE_URL: process.env.REACT_APP_ADMIN_API_BASE_URL,

};

export default GLOBAL;
