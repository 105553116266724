import React, { useContext, useEffect, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { IpWhitelistingApi } from '@mtt/admin-api';
import ApariDropdown from 'components/UI/ApariDropdown';
import setupAxios from 'services/axios';
import AppContext from 'AppContext';
import { Fade, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';

const WebhookHostsTableRow = ({ row, getIps }: { row: any; getIps: any }) => {
  const [active, setActive] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [updatedIp, setUpdatedIp] = useState<string>(row.ip);
  const [helperText, setHelperText] = useState(null);
  const [isError, setIsError] = useState(false);

  const { setShowSuccessAlert, setShowErrorAlert, setMessage } = useContext(AppContext);

  const ipWhitelistingApi = new IpWhitelistingApi();

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
  };

  useEffect(() => {
    setupAxios();
  });

  const handleEditSubmit = async (e: any) => {
    e.preventDefault();

    await fetch(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/protected/ip-whitelisting/${row.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        ip: updatedIp,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        setEditModal(false);
        setMessage('IP has been updated.');
        setShowSuccessAlert(true);
        getIps();
      })
      .catch((err) => {
        setIsError(true);
        setHelperText(err.response.data.message);
      });
  };

  const handleDeleteIp = async () => {
    ipWhitelistingApi
      .ipWhitelistingControllerDelete(row.id)
      .then(() => {
        setShowSuccessAlert(true);
        setMessage('IP has been deleted.');
        getIps();
      })
      .catch((err) => {
        setShowErrorAlert(true);
        setMessage(err.response.data.message);
      });
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} id={row.id}>
        <TableCell>{row.ip}</TableCell>
        <TableCell className="relative flex justify-between items-center">
          Active
          <ApariDropdown
            active={active}
            setActive={setActive}
            id={`apari-dropdown__btn--${row.ip.toString().replaceAll(/[./]/g, '-')}`}
          >
            <div className="apari-dropdown__menu">
              <ul className="apari-dropdown__menu--list">
                <li
                  id={`ip-whitelisting__edit--${row.ip.toString().replaceAll(/[./]/g, '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={() => {
                    setEditModal(true);
                    setActive(false);
                    setUpdatedIp(row.ip);
                  }}
                >
                  Edit IP
                </li>
                <li
                  id={`ip-whitelisting__remove--${row.ip.toString().replaceAll(/[./]/g, '-')}`}
                  className="apari-dropdown__menu--list-item"
                  onClick={handleDeleteIp}
                >
                  Remove IP
                </li>
              </ul>
            </div>
          </ApariDropdown>
        </TableCell>
      </TableRow>

      <Modal
        open={editModal}
        onClose={() => {
          setEditModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={editModal}>
          <Box sx={style}>
            <h2 className="apari__subheading mb-3">Edit IP</h2>

            <form onSubmit={handleEditSubmit}>
              <TextField
                id="ip-whitelisting__edit--input"
                fullWidth
                margin="normal"
                type="text"
                label="Edit IP"
                value={updatedIp}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUpdatedIp(e.target.value)}
                variant="standard"
                error={isError}
                helperText={helperText}
              />

              <div className="mt-6">
                <ApariPrimaryButton id="ip-whitelisting__save-btn" text="Save" type="submit" />
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default WebhookHostsTableRow;
