import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './dashboard.scss';

import { ErrorOutline, RadioButtonUnchecked } from '@mui/icons-material';

import { DefaultApi, WebhookConfigurationsApi } from '@mtt/admin-api';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AppContext from 'AppContext';
import ApariRadioButtonChecked from 'components/UI/ApariRadioButtonChecked';
import ListItem from 'components/UI/ListItem';
import MatUiStyledTable from 'components/UI/MatUiStyledTable';
import Cookies from 'js-cookie';
import setupAxios from 'services/axios';

const Dashboard = ({ setCheckJwt }: { setCheckJwt: React.Dispatch<SetStateAction<boolean>> }) => {
  const [clientCount, setClientCount] = useState(null);
  const [redirectCount, setRedirectCount] = useState(null);
  const [ipWhitelistingCount, setIpWhitelistingCount] = useState(null);
  const [userCount, setUserCount] = useState(null);
  const [sessionCount, setSessionCount] = useState(null);
  const [reportCount, setReportCount] = useState(null);
  const [name, setName] = useState(null);
  const [webhookIssuesCount, setWebhookIssuesCount] = useState(0);

  const { setHeight } = useContext(AppContext);

  const dashboardApi = new DefaultApi();
  const webhookConfigurationsApi = new WebhookConfigurationsApi();
  const navigate = useNavigate();

  const getWebhookConfigurations = async () => {
    webhookConfigurationsApi
      .webhookConfigurationControllerGetWebhookConfigurations()
      .then((res: any) => {
        for (const configuration of res.data) {
          fetch(
            `${process.env.REACT_APP_ADMIN_API_BASE_URL}/protected/webhook-configurations/results/${configuration.id}`,
            {
              headers: { 'Content-Type': 'application/json' },
              credentials: 'include',
            },
          )
            .then((res) => res.json())
            .then((data) => {
              data.map((result: any) => {
                if (result.response.message !== 'Success') {
                  setWebhookIssuesCount((prev) => prev + 1);
                }
              });
            });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setupAxios();
    setHeight(0);

    if (Cookies.get('JWT') === undefined) {
      navigate('/login');
      localStorage.clear();
      setCheckJwt(true);
    }

    // (async () => {
    dashboardApi
      .dashboardControllerGetNumberOfRedirectUrls()
      .then((res: any) => {
        setClientCount(res.data.clientCount);
        setRedirectCount(res.data.redirectUrlsCount);
        setIpWhitelistingCount(res.data.ipWhitelistingCount);
        setUserCount(res.data.usersCount);
        setSessionCount(res.data.sessionCount);
        setReportCount(res.data.reportCount);
        setName(res.data.name);
      })
      .catch(() => {
        navigate('/login');
        localStorage.clear();
      });

    getWebhookConfigurations();
    // })();
  }, []);

  return (
    // <>
    //   <div className="dashboard">
    //     <h1 className="dashboard__text">Welcome To The Apari Dashboard</h1>
    //     <div className="flex items-center mt-8">
    //       <div className="mr-2 bounce">
    //         <ArrowBackIcon fontSize="large" />
    //       </div>
    //       <p className="dashboard__text--paragraph">Please Navigate Using The Sidebar</p>
    //     </div>
    //   </div>
    // </>

    <>
      <h1 className="apari__heading mb-4">Setup Status</h1>
      <div className="md:flex">
        <div className="mr-24">
          <NavLink to="/client-setup">
            <div className="py-2">
              <ListItem
                icon={clientCount && clientCount > 0 ? <ApariRadioButtonChecked /> : <RadioButtonUnchecked />}
                name={`${clientCount} Client${clientCount && clientCount === 1 ? '' : 's'} setup`}
                iconStyle={{ color: '#673FFA', paddingRight: '12px' }}
                nameStyle={{ color: '#000' }}
              />
            </div>
          </NavLink>
          {/* <NavLink to="/mtls-certificates">
            <div className="py-2">
              <ListItem
                icon={<ApariRadioButtonChecked />}
                name="mTLS setup"
                iconStyle={{ color: '#673FFA', paddingRight: '12px' }}
                nameStyle={{ color: '#000' }}
              />
            </div>
          </NavLink> */}
          <NavLink to="/redirect-urls">
            <div className="py-2">
              <ListItem
                icon={redirectCount && redirectCount > 0 ? <ApariRadioButtonChecked /> : <RadioButtonUnchecked />}
                name={`${redirectCount} Redirect URL${redirectCount && redirectCount === 1 ? '' : 's'} setup`}
                iconStyle={{ color: '#673FFA', paddingRight: '12px' }}
                nameStyle={{ color: '#000' }}
              />
            </div>
          </NavLink>
          <NavLink to="/webhooks">
            <div className="py-2">
              <ListItem
                icon={webhookIssuesCount && webhookIssuesCount > 0 ? <ErrorOutline /> : <ApariRadioButtonChecked />}
                name={`${webhookIssuesCount} Webhook${
                  webhookIssuesCount && webhookIssuesCount === 1 ? '' : 's'
                } having issues`}
                iconStyle={{ color: '#673FFA', paddingRight: '12px' }}
                nameStyle={{ color: '#000' }}
              />
            </div>
          </NavLink>
        </div>
        <div>
          <NavLink to="/ip-whitelist">
            <div className="py-2">
              <ListItem
                icon={
                  ipWhitelistingCount && ipWhitelistingCount > 0 ? (
                    <ApariRadioButtonChecked />
                  ) : (
                    <RadioButtonUnchecked />
                  )
                }
                name={`${ipWhitelistingCount} IP${
                  ipWhitelistingCount && ipWhitelistingCount === 1 ? '' : 's'
                } whitelisted`}
                iconStyle={{ color: '#673FFA', paddingRight: '12px' }}
                nameStyle={{ color: '#000' }}
              />
            </div>
          </NavLink>
          <NavLink to="/users">
            <div className="py-2">
              <ListItem
                icon={userCount && userCount > 0 ? <ApariRadioButtonChecked /> : <RadioButtonUnchecked />}
                name={`${userCount} User${userCount && userCount === 1 ? '' : 's'} setup`}
                iconStyle={{ color: '#673FFA', paddingRight: '12px' }}
                nameStyle={{ color: '#000' }}
              />
            </div>
          </NavLink>
        </div>
      </div>

      <h1 className="apari__heading mt-9 mb-4">Company Information</h1>
      <MatUiStyledTable>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>No of Sessions</TableCell>
              <TableCell>No of Reports</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className="dashboard-table-row"
            >
              <TableCell>{name}</TableCell>
              <TableCell>{sessionCount}</TableCell>
              <TableCell>{reportCount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </MatUiStyledTable>
    </>
  );
};

export default Dashboard;
