import React from 'react';

import './ApariDropdown.scss';

import MoreVertIcon from '@mui/icons-material/MoreVert';

const ApariDropdown = (props: any) => {
  const handleClickEvent = (e: any) => {
    const currentDropdown = e.target.closest('[data-dropdown]');

    document.querySelectorAll('[data-dropdown].active').forEach((dropdown) => {
      if (dropdown === currentDropdown) return;
      dropdown.classList.remove('active');
      props.setActive((prev: any) => !prev);
    });
  };

  React.useEffect(() => {
    window.addEventListener('mousedown', handleClickEvent);

    return () => {
      window.removeEventListener('mousedown', handleClickEvent);
    };
  }, []);

  return (
    <div className={props.active ? 'apari-dropdown active' : 'apari-dropdown'} data-dropdown>
      <div className="apari-dropdown__wrapper absolute right-0 bottom-0 hover:cursor-pointer" id={props.id}>
        <MoreVertIcon onClick={() => props.setActive((prev: boolean) => !prev)} data-dropdown-button />
      </div>

      {props.children}
    </div>
  );
};

export default ApariDropdown;
