import React from 'react';

import './ApariPrimaryButton.scss';

const ApariPrimaryButton = ({
  type,
  text,
  onClick,
  id,
}: {
  type: 'button' | 'submit' | 'reset' | undefined;
  text: string;
  onClick?: any;
  id?: any;
}) => {
  return (
    <>
      <button className="apari__primary-btn" id={id} type={type} onClick={onClick}>
        {text}
      </button>
    </>
  );
};

export default ApariPrimaryButton;
