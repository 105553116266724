import React, { useEffect, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import FormField from 'components/UI/FormField';
import ApariPrimaryButton from 'components/UI/ApariPrimaryButton';
import { NavLink } from 'react-router-dom';

const Invitation = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formDetails = {
      Name: name,
      Password: password,
      'Confirm password': confirmPassword,
    };
    console.log(formDetails);

    setName('');
    setPassword('');
    setConfirmPassword('');
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);

    const x = document.getElementById('currentPasswordField') as HTMLInputElement;
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }

    // const y = document.getElementById('currentConfirmPasswordField') as HTMLInputElement;
    // if (y.type === 'password') {
    //   y.type = 'text';
    // } else {
    //   y.type = 'password';
    // }
  };

  useEffect(() => {
    if (password.length > 0) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  }, [password]);

  return (
    <>
      <div className="flex items-center justify-center h-full">
        <div>
          <h1 className="apari__heading mb-4">You have recieved an invite!</h1>
          <p className="apari__regular">
            You were invited by <strong>{'<Name>'}</strong>. Their details are listed below.
          </p>
          <ul className="mt-1 mb-4 ml-6">
            <li className="list-disc">
              Name: <strong>{'<Name>'}</strong>
            </li>
            <li className="list-disc">
              Email: <strong>{'<Email>'}</strong>
            </li>
          </ul>
          <p className="apari__regular">
            You were invited to join <strong>{'<Company name>'}</strong>.
          </p>

          <h2 className="apari__subheading mt-6 mb-2">Enter your details</h2>
          <form action="" onSubmit={handleSubmit} className="w-full lg:w-96">
            <FormField
              type="text"
              label="Name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              placeholder="Enter your name..."
            />
            <FormField
              type="password"
              label="Password"
              value={password}
              icon={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              onClick={toggleShowPassword}
              id="currentPasswordField"
              onChange={(e: any) => setPassword(e.target.value)}
              showIcon={showIcon}
              placeholder="Enter password..."
            />
            <FormField
              type="password"
              label="Confirm password"
              value={confirmPassword}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password..."
            />
            <NavLink to="two-factor-setup">
              <ApariPrimaryButton type="submit" text="Save" />
            </NavLink>
          </form>
        </div>
      </div>
    </>
  );
};

export default Invitation;
