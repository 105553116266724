import { IconButton, Snackbar, ThemeProvider } from '@mui/material';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import './App.scss';

import Alert from '@mui/material/Alert';

import BankConnections from 'components/Pages/BankConnections';
import ClientSetup from 'components/Pages/ClientSetup';
import Dashboard from 'components/Pages/Dashboard';
import Invitation from 'components/Pages/Invitation';
import IpWhitelist from 'components/Pages/IpWhitelist';
import Login from 'components/Pages/Login';
import MtlsCertificates from 'components/Pages/MtlsCertificates';
import Profile from 'components/Pages/Profile';
import RedirectUrls from 'components/Pages/RedirectUrls';
import TwoFactorRecoveryKey from 'components/Pages/TwoFactorRecoveryKey';
import TwoFactorSetup from 'components/Pages/TwoFactorSetup';
import Users from 'components/Pages/Users';
import WebhookHosts from 'components/Pages/WebhookHosts';
import Webhooks from 'components/Pages/Webhooks';
import ProtectedRoute from 'components/ProtectedRoute';
import Sidebar from 'components/Sidebar';
import TopNav from 'components/TopNav';
import apariArrow from './assets/APARI_Arrow.svg';
import theme from './styles';
import { AppProvider } from 'AppContext';
import SnackbarAlert from 'components/UI/SnackbarAlert';
import UpdatePassword from 'components/Pages/UpdatePassword';
import InviteExpired from 'components/Pages/InviteExpired';

function App() {
  const [user, setUser] = useState<unknown | null>(null);
  const [jwtFromCookie, setJwtFromCookie] = useState<undefined | string | null>(null);
  const [checkJwt, setCheckJwt] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const MINUTE_MS = 3600 * 1000;

  useEffect(() => {
    setJwtFromCookie(Cookies.get('JWT'));

    if (jwtFromCookie) {
      const decoded = jwt_decode(jwtFromCookie);
      setUser(decoded);
      localStorage.setItem('user', JSON.stringify(decoded));
    } else {
      setUser(null);
    }

    if (checkJwt) setCheckJwt(false);
  }, [jwtFromCookie, checkJwt]);

  useEffect(() => {
    const interval = setInterval(() => {
      const jwtCookie = Cookies.get('JWT');

      if (!jwtCookie) {
        setUser(null);
        localStorage.clear();
        setRedirect(true);
      }
    }, MINUTE_MS);

    if (!window.location.href.includes('/administration')) {
      window.location.replace(window.location.origin + '/administration/login');
    }

    return () => {
      clearInterval(interval), setRedirect(false);
    };
  }, [redirect]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AppProvider>
          <SnackbarAlert />

          <div className="arrow absolute right-[0] h-screen -z-10">
            <img className="h-full" src={apariArrow} alt="Apari Arrow" />
          </div>

          <Router basename="/administration">
            <>
              {redirect && <Navigate to="/login" />}

              {user && (
                // <ProtectedRoute isAllowed={user}>
                <TopNav user={user} setUser={setUser} />
                // </ProtectedRoute>
              )}
              <div className="lg:flex">
                <>
                  {/* {user && (
            <ProtectedRoute isAllowed={user}> */}
                  {user && <Sidebar user={user} />}
                  {/* </ProtectedRoute>
          )} */}

                  <div className="apari__app-container">
                    <Routes>
                      <Route path="/login" element={<Login user={user} setCheckJwt={setCheckJwt} />} />
                      <Route path="/activate-account" element={<UpdatePassword />} />
                      <Route path="/invite-expired" element={<InviteExpired />} />

                      <Route element={<ProtectedRoute isAllowed={user} />}>
                        <Route path="/dashboard" element={<Dashboard setCheckJwt={setCheckJwt} />} />
                        <Route path="/mtls-certificates" element={<MtlsCertificates />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/profile" element={<Profile user={user} setCheckJwt={setCheckJwt} />} />
                        <Route path="/apari-connections" element={<BankConnections />} />
                        <Route path="/client-setup" element={<ClientSetup />} />
                        <Route path="/redirect-urls" element={<RedirectUrls />} />
                        <Route path="/webhook-hosts" element={<WebhookHosts />} />
                        <Route path="/webhooks" element={<Webhooks />} />
                        <Route path="/ip-whitelist" element={<IpWhitelist user={user} />} />
                      </Route>

                      <Route path="/invitation" element={<Invitation />} />
                      <Route path="/two-factor-setup" element={<TwoFactorSetup />} />
                      <Route
                        path="/safe-recovery-key"
                        element={<TwoFactorRecoveryKey user={user} setCheckJwt={setCheckJwt} />}
                      />

                      <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                  </div>
                </>
              </div>
            </>
          </Router>
        </AppProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
