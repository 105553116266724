import React from 'react';
import PropTypes from 'prop-types';
import './ListItem.scss';

const ListItem = ({
  icon,
  iconStyle,
  name,
  nameStyle,
}: {
  icon: any;
  iconStyle: any;
  name: string;
  nameStyle: any;
}) => {
  return (
    <>
      <div className="nav-item">
        <div style={iconStyle}>{icon}</div>
        <p className="nav-item__name" style={nameStyle}>
          {name}
        </p>
      </div>
    </>
  );
};

ListItem.propTypes = {
  icon: PropTypes.any,
  iconStyle: PropTypes.any,
  nameStyle: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
};

export default ListItem;
