import React from 'react';

import './InviteExpired.scss';

const InviteExpired = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <h1 className="apari__heading text-center">
        <span className="invite__span">Oops!</span> Your invite has expired. Please ask your administrator to send you a
        new invite.
      </h1>
    </div>
  );
};

export default InviteExpired;
